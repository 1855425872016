import React from 'react';
import styled from 'styled-components';
import {
  InputGroup,
  InputRightAddon,
  InputLeftElement,
  Button,
  NumberInput,
  NumberInputField,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeadingText = styled.p`
  color: #303030;
  font-size: 0.85rem;
  margin-right: auto;
  margin-bottom: 0.75rem;
`;

const InputBox: typeof NumberInput = styled(NumberInput)`
  margin-bottom: 1rem;
  text-align: right;
  width: 100%;
  color: #303030;
  border-radius: 10px 0 0 10px !important;
  box-shadow: none !important;
`;

const InputField: typeof NumberInputField = styled(NumberInputField)`
  width: 100%;
  text-align: right;
  border: 1px solid var(--components-primary-orange) !important;
  padding-right: 10px !important;
  border-radius: 10px 0 0 10px !important;
  box-shadow: none !important;
`;

const IR = styled(InputRightAddon)`
  background: linear-gradient(90deg, #DF5E00 0%, #FF9C54 100%) !important;
  border: 1px #281d45 !important;
  color: white;
  font-weight: 600;
`;

const MaxButton = styled(Button)`
  width: 2.75rem !important;
  height: 50% !important;
  border-radius: 100px !important;
  background: linear-gradient(90deg, #DF5E00 0%, #FF9C54 100%) !important;
  color: white;
  font-size: 0.65rem !important;
  padding: 0.3rem !important;

  &:hover {
    filter: brightness(80%);
  }

  &:focus {
    box-shadow: none !important;
  }

  @media only screen and (max-width: 499px) {
    &:hover {
      filter: brightness(100%);
    }
  }
`;

interface Props {
  actionType: string;
  tokens: string[];
  asset: string;
  amount: string;
  maxAmount: string;
  onChange: (amount: string) => void;
  setAsset: React.Dispatch<React.SetStateAction<string>>;
  disabled: boolean;
}

export const MultiTokenInput: React.FC<Props> = ({
  actionType,
  tokens,
  asset,
  amount,
  maxAmount,
  onChange,
  setAsset,
  disabled = false,
}) => {
  return (
    <Container>
      <HeadingText>
        Enter an amount to {actionType}.
      </HeadingText>
      <InputGroup>
        <InputLeftElement width="3rem !important" marginLeft="8px">
          <MaxButton
            disabled={maxAmount === '' || disabled}
            onClick={() => onChange(maxAmount)}
          >
            MAX
          </MaxButton>
        </InputLeftElement>

        <InputBox
          value={amount}
          clampValueOnBlur
          isDisabled={disabled}
          onChange={(v) => {
            try {
              if (Number.isNaN(Number(v))) {
                return;
              }
            } catch {
              return;
            }

            onChange(v);
          }}
        >
          <InputField placeholder="0" />
        </InputBox>
        <IR>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            bg='transparent'
            _hover={{ bg: 'transparent' }}
            _active={{ bg: 'transparent' }}
            _focus={{ bg: 'transparent' }}
            padding={0}
            fontSize='0.85rem !important'
          >
            {asset}
          </MenuButton>
          <MenuList
            minW="0"
            w={'100px'}
            bg="linear-gradient(90deg, #DF5E00 0%, #FF9C54 100%)"
            border="transparent"
          >
            {tokens.map((token) => (
              <MenuItem
                fontSize='0.85rem !important'
                onClick={() => {
                  setAsset(token);
                }}
                bg={'transparent !important'} 
                _hover={{ filter: 'brightness(90%)', bg: 'transparent' }}
                key={token}
              >
                {token}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
        </IR>
      </InputGroup>
    </Container>
  );
};
