import { WarpTokenConfig } from "../types/types";

export const tokenList: WarpTokenConfig = [
  // Example collateral token for an EVM chain
  // {
  //   type: 'collateral',
  //   chainId: 5,
  //   address: '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6',
  //   hypCollateralAddress: '0x145de8760021c4ac6676376691b78038d3DE9097',
  //   name: 'Weth',
  //   symbol: 'WETH',
  //   decimals: 18,
  //   logoURI: '/logos/weth.png', // See public/logos/
  // },
  // Example native token for an EVM chain
  // {
  //   type: 'native',
  //   chainId: 11155111,
  //   name: 'Ether',
  //   symbol: 'ETH',
  //   decimals: 18,
  //   hypNativeAddress: '0xEa44A29da87B5464774978e6A4F4072A4c048949',
  //   logoURI: '/logos/weth.png',
  // },
  // {
  //   type: 'collateral',
  //   chainId: 5, // goerli
  //   address: '0xbaf289a8c7A9809E13ac81dC073BD10E051de1Df', // usdc address goerli
  //   hypCollateralAddress: '0x6D46AdD95cb4314931ECDef70fc1FEFF4efD2928', // router addr
  //   name: 'USDC',
  //   symbol: 'USDC',
  //   decimals: 6,
  // },
  // {
  //   type: 'collateral',
  //   chainId: 84531, // base goerli
  //   address: '0xC9EA92b0D53eAB5e8753B88B077aF94461a7AdBC', // usdc address base goerli
  //   hypCollateralAddress: '0x8F8F245244C6C72cA112FB1Df3875310Ee9b8bf5', // router addr
  //   name: 'USDC',
  //   symbol: 'USDC',
  //   decimals: 18,
  // },
  // {
  //   type: 'collateral',
  //   chainId: 2511, // karak goerli
  //   address: '0xb2cA8e09e919E91F1bc50Bf940b16C7b75c72bec', // usdc address karak goerli
  //   hypCollateralAddress: '0xC35cc432BFAc80336C4B7f0820Bd37e4c2f056d4', // router addr
  //   name: 'USDC',
  //   symbol: 'USDC',
  //   decimals: 6,
  // },
  // {
  //   type: 'collateral',
  //   chainId: 420, // optimism goerli
  //   address: '0xe05606174bac4A6364B31bd0eCA4bf4dD368f8C6', // usdc address optimism goerli
  //   hypCollateralAddress: '0x1467829d4EDCf4b9812f12B3E5546f09bc9aB92d', // router addr
  //   name: 'USDC',
  //   symbol: 'USDC',
  //   decimals: 6,
  // },
  {
    type: 'collateral',
    chainId: 2410, // karak
    address: '0xa415021bC5c4C3b5B989116DC35Ae95D9C962c8D', // usdc address karak
    hypCollateralAddress: '0xFD8728bC035ff46A24aa082b80405b167401debE', // router addr
    name: 'USDC',
    symbol: 'USDC',
    decimals: 6,
  },
  {
    type: 'collateral',
    chainId: 10, // optimism
    address: '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85', // usdc address optimism
    hypCollateralAddress: '0x34709CA4779d2E9fEFD14E5b22C6De75B0188C7d', // router addr
    name: 'USDC',
    symbol: 'USDC',
    decimals: 6,
  },
  {
    type: 'collateral',
    chainId: 42161, // arbitrum
    address: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831', // usdc address arbitrum
    hypCollateralAddress: '0x34709CA4779d2E9fEFD14E5b22C6De75B0188C7d', // router addr
    name: 'USDC',
    symbol: 'USDC',
    decimals: 6,
  },
  {
    type: 'native',
    chainId: 2410, // karak
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
    hypNativeAddress: '0x02A3dF6734Cb39AE47Cda4dB4AE99F2b076bf56e',
  },
  {
    type: 'native',
    chainId: 10, // optimism
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
    hypNativeAddress: '0xC5495E2532E7D293057219884b6fB032B0007c93',
  },
  {
    type: 'native',
    chainId: 42161, // arbitrum
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
    hypNativeAddress: '0xC5495E2532E7D293057219884b6fB032B0007c93',
  },
  // {
  //   type: 'collateral',
  //   chainId: 2511, // karak goerli
  //   address: '0xE871A4d3047906bb38FFF9471cCfCc088cD4EFb1', // usdc address karak goerli
  //   hypCollateralAddress: '0xf187D2540A184E985cD0bBA276C7D41AF0F4Dae9', // router addr
  //   name: 'USDC',
  //   symbol: 'USDC',
  //   decimals: 6,
  // },
  // {
  //   type: 'collateral',
  //   chainId: 420, // optimism goerli
  //   address: '0x5808ac9b575f014Cd323bfeE4dcb5D5955AF0573', // usdc address optimism goerli
  //   hypCollateralAddress: '0x5C4C2a3CA4304bF8C6967E79618fBcC1d090Bf72', // router addr
  //   name: 'USDC',
  //   symbol: 'USDC',
  //   decimals: 6,
  // },
  // {
  //   type: 'collateral',
  //   chainId: 2511, // karak testnet
  //   address: '0xE871A4d3047906bb38FFF9471cCfCc088cD4EFb1', // usdc address karak testnet
  //   hypCollateralAddress: '0xC141c44Ef9029112A8e1029da6ac781ECE872f0a',
  //   name: 'USDC',
  //   symbol: 'USDC',
  //   decimals: 6,
  // },
  // {
  //   type: 'collateral',
  //   chainId: 5, // goerli
  //   address: '0xbaf289a8c7A9809E13ac81dC073BD10E051de1Df',
  //   hypCollateralAddress: '0x05532718e9609265D47d113ccd8c27580D66A889',
  //   name: 'USDC',
  //   symbol: 'USDC',
  //   decimals: 6,
  // },
  // {
  //   type: 'collateral',
  //   chainId: 420, // optimism goerli
  //   address: '0x5808ac9b575f014Cd323bfeE4dcb5D5955AF0573',
  //   hypCollateralAddress: '0xF02fA912c15d44Bf26f69D5eF1487233A3bcBfCb',
  //   name: 'USDC',
  //   symbol: 'USDC',
  //   decimals: 6,
  // },
  // {
  //   type: 'collateral',
  //   chainId: 421_613,
  //   address: '0x13501FC54762069e30b2d0D0B8E795fbFB977E9d',
  //   hypCollateralAddress: '0x4be06A35822f0C9a984a6da544EE283f5fB41986',
  //   name: 'USDC',
  //   symbol: 'USDC',
  //   decimals: 6,
  // },
  // {
  //   type: 'native',
  //   chainId: 2511, // karak testnet
  //   name: 'Karak Ether',
  //   symbol: 'ETH',
  //   decimals: 18,
  //   hypNativeAddress: '0xd992C38F5b8522C7Ee50c80b1533e20276CFb144',
  // },
  // {
  //   type: 'native',
  //   chainId: 5, // goerli
  //   name: 'Ether',
  //   symbol: 'ETH',
  //   decimals: 18,
  //   hypNativeAddress: '0xC1698429A830A76c438F2C259D5B94F5a8EAee30',
  // },
  // {
  //   type: 'native',
  //   chainId: 420, // optimism goerli
  //   name: 'Ether',
  //   symbol: 'ETH',
  //   decimals: 18,
  //   hypNativeAddress: '0x8F8F245244C6C72cA112FB1Df3875310Ee9b8bf5',
  // },
  // {
  //   type: 'native',
  //   chainId: 421_613, // arbitrum goerli
  //   name: 'Ether',
  //   symbol: 'ETH',
  //   decimals: 18,
  //   hypNativeAddress: '0xea1c3641F214075F0BcC0e98E57E83Ced4386070',
  // },
];
