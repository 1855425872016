import { Text, Box, Heading, Center} from "@chakra-ui/react";
import { useEffect } from "react";
import styled from 'styled-components';

const Page = styled(Center)`
  position: relative;
  flex-direction: column;
  margin: auto;
`;

const StyledHeading = styled(Heading)`
  font-size: 3rem !important;
  color: black;
  text-align: center;
  font-weight: 700 !important;
  font-family: Gilroy !important;
`;

const Copy = styled(Text)`
  font-size: 1.15rem;
  color: black;
  margin-top: 2rem;
  font-family: Nunito Sans;
  text-align: center;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  width: 40rem;

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

const About = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page>
      <Box marginTop="2rem" />
      <StyledHeading>The Karak Foundation</StyledHeading>
      <Flex>
        <Copy>
          The Karak Foundation is an independent, non-profit foundation dedicated to the decentralization,
          adoption, and security of the Karak Network, a universal network for verifiable finance.
        </Copy>
      </Flex>
      <Box marginBottom="2rem" />
    </Page>
  );
}

export default About;
