import { AdapterFactory } from "../tokens/AdapterFactory";
import { HyperlaneCore } from '@hyperlane-xyz/sdk';
import { convertDecimals } from '@hyperlane-xyz/utils';
import { Route } from "../types";
import { isRouteToCollateral, isWarpRoute } from "../routes/utils";
import { Logger } from "../../utils/logger";
import { BigNumber, providers } from "ethers";

// In certain cases, like when a synthetic token has >1 collateral tokens
// it's possible that the collateral contract balance is insufficient to
// cover the remote transfer. This ensures the balance is sufficient or throws.
export async function ensureSufficientCollateral(route: Route, weiAmount: string) {
  if (!isRouteToCollateral(route)) return;
  if (!isWarpRoute(route)) return;

  Logger.debug('Ensuring collateral balance for route', route);
  const adapter = AdapterFactory.HypTokenAdapterFromRouteDest(route);
  const destinationBalance = await adapter.getBalance(route.destRouterAddress);
  const destinationBalanceInOriginDecimals = convertDecimals(
    route.destDecimals,
    route.originDecimals,
    destinationBalance,
  );
  if (BigNumber.from(destinationBalanceInOriginDecimals).lt(weiAmount)) {
    Logger.info('Insufficient collateral balance');
    throw new Error('Insufficient collateral balance');
  }
}

export function tryGetMsgIdFromEvmTransferReceipt(receipt: providers.TransactionReceipt) {
  try {
    const messages = HyperlaneCore.getDispatchedMessages(receipt);
    if (messages.length) {
      const msgId = messages[0].id;
      Logger.debug('Message id found in logs', msgId);
      return msgId;
    } else {
      Logger.warn('No messages found in logs');
      return undefined;
    }
  } catch (error) {
    Logger.error('Could not get msgId from transfer receipt', error);
    return undefined;
  }
}
