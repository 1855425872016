import numeral from "numeral";
import Decimal from "decimal.js";
import { FixedPointNumber } from "./fixedPoint";

export const decimalFormat = (amount: string) => {
  return numeral(Number(amount).toFixed(4)).format(`0.00[00]a`);
};

export const mapRawAmountToUi = (rawAmount: string, decimals: number) => {
  return new Decimal(rawAmount).div(new Decimal(10).pow(decimals)).toString();
};

export const formatXP = (amount: number) => {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const isEmpty = (val: string | null) => {
  return !val || val === 'undefined' || val === 'null';
};

export const checkInsufficientBalance = (val: string, decimals: number, balance: string) => {
  return new Decimal(balance).lessThan(new Decimal(FixedPointNumber.fromDecimal(val, decimals).toRawString()));
}
