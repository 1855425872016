import React from 'react';
import { Box, Button, HStack, VStack,  Link } from '@chakra-ui/react';
import styled from 'styled-components';
import karakParticles from '../../assets/karak-particles.png';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10rem;
  width: 100%;

  @media only screen and (max-width: 872px) {
    margin-bottom: 8rem;
  }
`;

const ButtonContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;

  @media only screen and (max-width: 780px) {
    align-items: center;
  }

  @media only screen and (max-width: 872px) {
    margin-top: 0rem;
  }
`;

const SubButtonContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  column-gap: 1rem;
`;

const HeadingTitle = styled.h1`
  color: #303030;
  font-family: Gilroy;
  font-weight: 700;
  font-size: calc(2.15 * var(--h1-font-size));
  margin-bottom: var(--grid-item-bm);
  line-height: 1.15;
  width: 22.15rem;
  margin-top: 3rem;

  @media only screen and (max-width: 900px) {
    width: 100%;
  }

  @media only screen and (max-width: 767px) {
    font-size: calc(1.75 * var(--h1-font-size));
  }

  @media only screen and (max-width: 730px) {
    text-shadow: none;
    margin-top: 0rem;
  }
`;

const SubHeading = styled.p`
  font-size: 1.25rem;
  font-family: Nunito Sans;
  font-weight: 400;
  color: #303030;
  line-height: 111.5%;
  margin-bottom: var(--grid-item-bm);
  overflow-wrap: break-word;

  @media only screen and (max-width: 900px) {
    width: 80%;
    font-size: 1.4rem;
  }

  @media only screen and (max-width: 767px) {
    font-size: 1.2rem;
    width: 100%;
  }
`;

const KarakParticles = styled.img`
  width: 32rem;

  @media only screen and (max-width: 780px) {
    display: none;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30rem;

  @media only screen and (max-width: 780px) {
    align-items: center;
    text-align: center;
    width: 100%;
  }
`;

const Hero = () => {
  return (
    <Container zIndex="999">
      <HStack mt="2rem">
        <TextContainer>
          <HeadingTitle>
            Restaking for everyone
          </HeadingTitle>
          <SubHeading>
            Karak is the universal restaking layer powered by the entire cryptoeconomy across every asset and every chain.
          </SubHeading>
          <ButtonContainer>
            <SubButtonContainer>
              <Button
                as={Link}
                href="https://app.karak.network"
                isExternal
                _hover={{textDecoration: 'none', filter: 'brightness(95%)'}}
                _active={{color: 'transparent'}}
                _focus={{outline: 'none', boxShadow: 'none'}}
                px="var(--button-px)"
                py="var(--button-py)"
                textColor="white"
                background="var(--components-primary-orange)"
                fontSize="0.95rem"
                borderRadius="5px"
                width="11rem"
                height="3rem"
                fontFamily="Inter"
                fontWeight="700"
                className="hero-button"
              >
                Restake now
              </Button>
              <Button
                as={Link}
                href="https://docs.karak.network"
                isExternal
                _hover={{textDecoration: 'none', filter: 'brightness(95%)'}}
                _active={{color: 'transparent'}}
                _focus={{outline: 'none', boxShadow: 'none'}}
                px="var(--button-px)"
                py="var(--button-py)"
                textColor="#303030"
                target="_new"
                background="rgba(255, 255, 255, 0.1)"
                fontSize="0.9rem"
                border="0.75px solid rgba(48, 48, 48, 0.20)"
                borderRadius="5px"
                width="11rem"
                height="3rem"
                fontFamily="Inter"
                fontWeight="700"
                className="hero-button"
              >
                Learn more
              </Button>
            </SubButtonContainer>
          </ButtonContainer>
        </TextContainer>
        <VStack>
          <KarakParticles src={karakParticles} alt="karak-particles" />
        </VStack>
      </HStack>
    </Container>
  );
}

export default Hero;
