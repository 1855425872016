import { ProtocolType, convertDecimals } from "@hyperlane-xyz/utils";
import { getCaip2Id } from "../hyperlane/caip/chains";
import { RoutesMap, WarpRoute } from "../hyperlane/types";
import { AssetNamespace, getCaip19Id } from "../hyperlane/caip/tokens";
import { zeroAddress } from "viem";
import { findCollateralTokenAddressByChainId } from "../hyperlane/tokens/metadata";
import { getTokenRoute } from "../hyperlane/routes/utils";
import { AdapterFactory } from "../hyperlane/tokens/AdapterFactory";
import { arbitrum, optimism } from "viem/chains";
import { Karak } from "../contexts/WagmiConfig";

// origin id op -> destination id karak token usdc
// origin id op -> destination id karak token eth
// origin id arb -> destination id karak token usdc
// origin id arb -> destination id karak token eth

// origin id karak -> destination id op token usdc
// origin id karak -> destination id op token eth
// origin id karak -> destination id arb token usdc
// origin id karak -> destination id arb token eth

export type CollateralBalances = {
  ethBalance: string;
  usdcBalance: string;
}

export type KarakBridgeCollateral =  {
  'karak': CollateralBalances;
  'optimism': CollateralBalances;
  'arbitrum': CollateralBalances
}

export async function fetchDestinationBalance(
  originChainId: number,
  destinationChaindId: number,
  asset:string,
  tokenRoutes: RoutesMap
) {
  const originCaip2Id = getCaip2Id(ProtocolType.Ethereum, originChainId);
  const destinationCaip2Id = getCaip2Id(ProtocolType.Ethereum, destinationChaindId);
  const tokenCaip19Id = asset === 'ETH'
    ? getCaip19Id(originCaip2Id, AssetNamespace.native, zeroAddress)
    : getCaip19Id(originCaip2Id, AssetNamespace.erc20, findCollateralTokenAddressByChainId(originChainId));
  const tokenRoute = getTokenRoute(originCaip2Id, destinationCaip2Id, tokenCaip19Id, tokenRoutes) as WarpRoute;

  if (!tokenRoute) return '0';

  const adapter = AdapterFactory.HypTokenAdapterFromRouteDest(tokenRoute);
  const destinationBalance = await adapter.getBalance(tokenRoute.destRouterAddress);
  const destinationBalanceInOriginDecimals = convertDecimals(
    tokenRoute.destDecimals,
    tokenRoute.originDecimals,
    destinationBalance,
  );
  return destinationBalanceInOriginDecimals;
}

export async function fetchKarakDestinationBalances(tokenRoutes: RoutesMap): Promise<CollateralBalances> {
  const karakEth = await fetchDestinationBalance(optimism.id, Karak.id, 'ETH', tokenRoutes);
  const karakUSDC = await fetchDestinationBalance(optimism.id, Karak.id, 'USDC', tokenRoutes);
  return {
    ethBalance: karakEth,
    usdcBalance: karakUSDC,
  };
}
  
export async function fetchOPDestinationBalances(tokenRoutes: RoutesMap): Promise<CollateralBalances> {
  const opEth = await fetchDestinationBalance(Karak.id, optimism.id, 'ETH', tokenRoutes);
  const opUSDC = await fetchDestinationBalance(Karak.id, optimism.id, 'USDC', tokenRoutes);
  return {
    ethBalance: opEth,
    usdcBalance: opUSDC,
  };
}
  
  
export async function fetchArbDestinationBalances(tokenRoutes: RoutesMap): Promise<CollateralBalances> {
  const arbEth = await fetchDestinationBalance(Karak.id, arbitrum.id, 'ETH', tokenRoutes);
  const arbUSDC = await fetchDestinationBalance(Karak.id, arbitrum.id, 'USDC', tokenRoutes);
  return {
    ethBalance: arbEth,
    usdcBalance: arbUSDC,
  };
}
