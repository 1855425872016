import { ChainMap, ChainMetadata } from '@hyperlane-xyz/sdk';
import { ProtocolType } from '@hyperlane-xyz/utils';

// A map of chain names to ChainMetadata
export const chains: ChainMap<ChainMetadata & { mailbox?: Address }> = {
  // ----------- Add your chains here -----------------
  // Chains already in the SDK need not be included here. Example custom chain:
  // mycustomchain: {
  //   protocol: ProtocolType.Ethereum,
  //   chainId: 1234,
  //   domainId: 1234,
  //   name: 'mycustomchain',
  //   displayName: 'My Chain',
  //   nativeToken: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  //   publicRpcUrls: [{ http: 'https://mycustomchain-rpc.com' }],
  //   blockExplorers: [
  //     {
  //       name: 'MyCustomScan',
  //       url: 'https://mycustomchain-scan.com',
  //       apiUrl: 'https://api.mycustomchain-scan.com/api',
  //       family: ExplorerFamily.Etherscan,
  //     },
  //   ],
  //   blocks: {
  //     confirmations: 1,
  //     reorgPeriod: 1,
  //     estimateBlockTime: 10,
  //   },
  //   logoURI: '/logo.svg',
  // },
  // karak: {
  //   protocol: ProtocolType.Ethereum,
  //   chainId: 2511,
  //   domainId: 2511,
  //   name: 'karak',
  //   displayName: 'KarakTestnet',
  //   nativeToken: {
  //     name: 'Karak Ether',
  //     symbol: 'ETH',
  //     decimals: 18,
  //   },
  //   rpcUrls: [
  //     {
  //       http: 'https://goerli.node1.karak.network',
  //     },
  //   ],
  //   blockExplorers: [
  //     {
  //       name: 'Blockscout',
  //       url: 'https://goerli.scan.karak.network',
  //       apiUrl: 'https://goerli.node1.karak.network' // TODO add api url for block explorer
  //     },
  //   ]
  // },
  karak: {
    protocol: ProtocolType.Ethereum,
    chainId: 2410,
    domainId: 2410,
    name: 'karak',
    displayName: 'Karak',
    nativeToken: {
      name: 'Karak Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [
      {
        http: 'https://rpc.karak.network',
      },
    ],
    blockExplorers: [
      {
        name: 'Blockscout',
        url: 'https://explorer.karak.network',
        apiUrl: 'https://rpc.karak.network' // TODO add api url for block explorer
      },
    ]
  },
};
