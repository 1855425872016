import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { WithdrawBridgeMessage, getPendingWithdrawals } from '../actions/offboarding-withdraw';
import { Logger } from '../utils/logger';
import { Maybe } from '../utils/maybe';

export type OffboardingWithdrawalsContextValue = {
  pendingWithdrawals: WithdrawBridgeMessage[];
};

const OffboardingWithdrawalsContext = React.createContext<Maybe<OffboardingWithdrawalsContextValue>>(
  Maybe.none()
);

export function useOffboardingWithdrawalsContext() {
  return useContext(OffboardingWithdrawalsContext);
}

export const OffboardingWithdrawalsProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { address } = useAccount();
  const [withdrawals, setWithdrawals] = useState<Maybe<OffboardingWithdrawalsContextValue>>(
    Maybe.none()
  );

  useEffect(() => {
    const cleanup = () => {
      setWithdrawals(Maybe.none());
    };

    if (!address) {
      setWithdrawals(Maybe.none());
      return;
    };

    const fetchWithdrawals = async () => {
      try {
        const pendingWithdrawals = await getPendingWithdrawals(address);
        setWithdrawals(Maybe.some({ pendingWithdrawals }));
      } catch (e) {
        setWithdrawals(Maybe.some({ pendingWithdrawals: [] }));
        Logger.error('Failed to retrieve pending withdrawals', e);
      }
    }
    fetchWithdrawals();

    return cleanup;
  }, [address]);

  return (
    <OffboardingWithdrawalsContext.Provider value={withdrawals}>
      {children}
    </OffboardingWithdrawalsContext.Provider>
  );
};
