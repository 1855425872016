import React from 'react';
import { Box } from '@chakra-ui/react';
import styled from 'styled-components';

const Container = styled(Box)`
  padding-top: 1rem;
  width: 1200px;
  margin: auto;
  margin-bottom: 5rem;
  
  @media only screen and (max-width: 1244px) {
    width: 100%;
  }
`;

const Heading = styled.h1`
  color: #303030;
  font-size: 2rem;
  margin-bottom: 2rem;
  line-height: var(--header-line-height);
  font-weight: 550;
  letter-spacing: -1.2px;
  font-family: Nunito Sans;
`;

const Copy = styled.p`
  font-size: 1rem;
  color: #303030;
  margin-bottom: 1rem;
  font-family: Nunito Sans;
`;

const TermsOfService = () => {
  return (
    <Container>
      <Heading>Terms of Use</Heading>
      <Copy>Latest Update: February 2024</Copy>
      <Copy>Please read these Terms of Service (the “Terms”) and our Privacy Policy (“Privacy Policy”) carefully because they govern your use of the website located at www.karak.network (the “Site”) and services accessible via the Site offered by Karak Foundation (“Karak Foundation”). To make these Terms easier to read, the Site and our services are collectively called the “Services.”</Copy>
      <Copy>IMPORTANT NOTICE REGARDING ARBITRATION FOR U.S. CUSTOMERS: WHEN YOU AGREE TO THESE TERMS YOU ARE AGREEING (WITH LIMITED EXCEPTION) TO RESOLVE ANY DISPUTE BETWEEN YOU AND KARAK FOUNDATION THROUGH BINDING, INDIVIDUAL ARBITRATION RATHER THAN IN COURT. PLEASE REVIEW CAREFULLY SECTION 15 “DISPUTE RESOLUTION” BELOW FOR DETAILS REGARDING ARBITRATION.</Copy>
      <Copy>1. Agreement to Terms. By using our Services, you agree to be bound by these Terms. If you don’t agree to be bound by these Terms, do not use the Services.</Copy>
      <Copy>2. Privacy Policy. Please review our Privacy Policy, which also governs your use of the Services, for information on how we collect, use and share your information.</Copy>
      <Copy>3. Changes to these Terms or the Services. We may update the Terms from time to time in our sole discretion. If we do, we’ll let you know by posting the updated Terms on the Site and/or may also send other communications. It’s important that you review the Terms whenever we update them or you use the Services. If you continue to use the Services after we have posted updated Terms it means that you accept and agree to the changes. If you don’t agree to be bound by the changes, you may not use the Services anymore. Because our Services are evolving over time we may change or discontinue all or any part of the Services, at any time and without notice, at our sole discretion.</Copy>
      <Copy>4. Who May Use the Services?</Copy>
      <Copy>You may use the Services only if you are 18 years or older and capable of forming a binding contract with Karak Foundation, and not otherwise barred from using the Services under applicable law.</Copy>
      <Copy>5. Feedback. We appreciate feedback, comments, ideas, proposals and suggestions for improvements to the Services (“Feedback”). If you choose to submit Feedback, you agree that we are free to use it without any restriction or compensation to you.</Copy>
      <Copy>6. Your Content.</Copy>
      <Copy>(a) Posting Content. Our Services may allow you to store or share content such as text (in posts or communications with others), files, documents, graphics, images, music, software, audio and video. Anything (other than Feedback) that you post or otherwise make available through the Services is referred to as “User Content”. Karak Foundation does not claim any ownership rights in any User Content and nothing in these Terms will be deemed to restrict any rights that you may have to your User Content.</Copy>
      <Copy>(b) Permissions to Your User Content. By making any User Content available through the Services you hereby grant to Karak Foundation a non-exclusive, transferable, worldwide, royalty-free license, with the right to sublicense, to use, copy, modify, create derivative works based upon, distribute, publicly display, and publicly perform your User Content in connection with operating and providing the Services.</Copy>
      <Copy>(c) Your Responsibility for User Content. You are solely responsible for all your User Content. You represent and warrant that you have (and will have) all rights that are necessary to grant us the license rights in your User Content under these Terms. You represent and warrant that neither your User Content, nor your use and provision of your User Content to be made available through the Services, nor any use of your User Content by Karak Foundation on or through the Services will infringe, misappropriate or violate a third party’s intellectual property rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.</Copy>
      <Copy>(d) Removal of User Content. You can remove your User Content by specifically deleting it. You should know that in certain instances, some of your User Content (such as posts or comments you make) may not be completely removed and copies of your User Content may continue to exist on the Services. To the maximum extent permitted by law, we are not responsible or liable for the removal or deletion of (or the failure to remove or delete) any of your User Content.</Copy>
      <Copy>(e) Karak Foundation’s Intellectual Property. We may make available through the Services content that is subject to intellectual property rights. We retain all rights to that content.</Copy>
      <Copy>7. General Prohibitions and Karak Foundation’s Enforcement Rights. You agree not to do any of the following:</Copy>
      <Copy>(a) Post, upload, publish, submit or transmit any User Content that: (i) infringes, misappropriates or violates a third party’s patent, copyright, trademark, trade secret, moral rights or other intellectual property rights, or rights of publicity or privacy; (ii) violates, or encourages any conduct that would violate, any applicable law or regulation or would give rise to civil liability; (iii) is fraudulent, false, misleading or deceptive; (iv) is defamatory, obscene, pornographic, vulgar or offensive; (v) promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group; (vi) is violent or threatening or promotes violence or actions that are threatening to any person or entity; or (vii) promotes illegal or harmful activities or substances;</Copy>
      <Copy>(b) Use, display, mirror or frame the Services or any individual element within the Services, Karak Foundation’s name, any Karak Foundation trademark, logo or other proprietary information, or the layout and design of any page or form contained on a page, without Karak Foundation’s express written consent;</Copy>
      <Copy>(c) Access, tamper with, or use non-public areas of the Services, Karak Foundation’s computer systems, or the technical delivery systems of Karak Foundation’s providers;</Copy>
      <Copy>(d) Attempt to probe, scan or test the vulnerability of any Karak Foundation system or network or breach any security or authentication measures;</Copy>
      <Copy>(e) Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological measure implemented by Karak Foundation or any of Karak Foundation’s providers or any other third party (including another user) to protect the Services;</Copy>
      <Copy>(f) Attempt to access or search the Services or download content from the Services using any engine, software, tool, agent, device or mechanism (including spiders, robots, crawlers, data mining tools or the like) other than the software and/or search agents provided by Karak Foundation or other generally available third-party web browsers;</Copy>
      <Copy>(g) Send any unsolicited or unauthorized advertising, promotional materials, email, junk mail, spam, chain letters or other form of solicitation;</Copy>
      <Copy>(h) Use any meta tags or other hidden text or metadata utilizing a Karak Foundation trademark, logo URL or product name without Karak Foundation’s express written consent;</Copy>
      <Copy>(i) Use the Services, or any portion thereof, for any commercial purpose or for the benefit of any third party or in any manner not permitted by these Terms;</Copy>
      <Copy>(j) Forge any TCP/IP packet header or any part of the header information in any email or newsgroup posting, or in any way use the Services to send altered, deceptive or false source-identifying information;</Copy>
      <Copy>(k) Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Services;</Copy>
      <Copy>(l) Interfere with, or attempt to interfere with, the access of any user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services;</Copy>
      <Copy>(m) Collect or store any personally identifiable information from the Services from other users of the Services without their express permission;</Copy>
      <Copy>(n) Impersonate or misrepresent your affiliation with any person or entity;</Copy>
      <Copy>(o) Violate any applicable law or regulation; or</Copy>
      <Copy>(p) Encourage or enable any other individual to do any of the foregoing.</Copy>
      <Copy>Karak Foundation is not obligated to monitor access to or use of the Services or to review or edit any content. However, we have the right to do so for the purpose of operating the Services, to ensure compliance with these Terms and to comply with applicable law or other legal requirements. We reserve the right, but are not obligated, to remove or disable access to any content, including User Content, at any time and without notice, including, but not limited to, if we, at our sole discretion, consider it objectionable or in violation of these Terms. We have the right to investigate violations of these Terms or conduct that affects the Services. We may also consult and cooperate with law enforcement authorities to prosecute users who violate the law.</Copy>
      <Copy>8. DMCA/Copyright Policy. Karak Foundation respects copyright law and expects its users to do the same. It is Karak Foundation’s policy to terminate in appropriate circumstances account holders who repeatedly infringe or are believed to be repeatedly infringing the rights of copyright holders. Please see Karak Foundation’s Copyright and IP Policy, for further information.</Copy>
      <Copy>9. Links to Third Party Websites or Resources. The Services may allow you to access third-party websites or other resources. We provide access only as a convenience and are not responsible for the content, products or services on or available from those resources or links displayed on such websites. You acknowledge sole responsibility for and assume all risk arising from your use of any third-party resources.</Copy>
      <Copy>10. Termination. We may suspend or terminate your access to and use of the Services, including suspending access to or terminating your account, at our sole discretion, at any time and without notice to you. You may cancel your account at any time by contacting us. Upon any termination, discontinuation or cancellation of the Services or your account, the following Sections will survive: 6(b), 6(c), 6(e), 7, 10, 11, 12, 13, 14, 15 and 16.</Copy>
      <Copy>11. Warranty Disclaimers. THE SERVICES ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. We make no warranty that the Services will meet your requirements or be available on an uninterrupted, secure, or error-free basis. We make no warranty regarding the quality, accuracy, timeliness, truthfulness, completeness or reliability of any information or content on the Services.</Copy>
      <Copy>12. Indemnity. You will indemnify and hold Karak Foundation and its officers, directors, employees and agents, harmless from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses, including, without limitation, reasonable legal and accounting fees arising out of or in any way connected with (a) your access to or use of the Services, (b) your User Content, or (c) your violation of these Terms.</Copy>
      <Copy>13. Limitation of Liability.</Copy>
      <Copy>(a) TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER KARAK FOUNDATION NOR ITS SERVICE PROVIDERS INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICES WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST PROFITS, LOST REVENUES, LOST SAVINGS, LOST BUSINESS OPPORTUNITY, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE SERVICES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT KARAK FOUNDATION OR ITS SERVICE PROVIDERS HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.</Copy>
      <Copy>(b) TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WILL KARAK FOUNDATION’S TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES EXCEED THE AMOUNTS YOU HAVE PAID OR ARE PAYABLE BY YOU TO KARAK FOUNDATION FOR USE OF THE SERVICES OR ONE HUNDRED DOLLARS ($100), IF YOU HAVE NOT HAD ANY PAYMENT OBLIGATIONS TO KARAK FOUNDATION, AS APPLICABLE.</Copy>
      <Copy>(c) THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN KARAK FOUNDATION AND YOU.</Copy>
      <Copy>14. Governing Law and Forum Choice. These Terms and any action related thereto will be governed by the Cayman International Arbitration Centre and Cayman Islands arbitration law without regard to its conflict of laws provisions. Except as otherwise expressly set forth in Arbitration Law, 2012 “Arbitration Law,” the exclusive jurisdiction for all Disputes (defined below) that you and Karak Foundation are not required to arbitrate will be the courts located in the Cayman Islands, and you and Karak Foundation each waive any objection to jurisdiction and venue in such courts.</Copy>
      <Copy>15. Dispute Resolution.</Copy>
      <Copy>(a) Mandatory Arbitration of Disputes. We each agree that any dispute, claim or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation or validity thereof or the use of the Services (collectively, “Disputes”) will be resolved solely by binding, individual arbitration and not in a class, representative or consolidated action or proceeding. You and Karak Foundation agree that the Cayman Islands Arbitration Law governs the interpretation and enforcement of these Terms, and that you and Karak Foundation are each waiving the right to a trial by jury or to participate in a class action. This arbitration provision shall survive termination of these Terms.</Copy>
      <Copy>(b) Exceptions. As limited exceptions to Section 15(a) above: (i) we both may seek to resolve a Dispute in small claims court if it qualifies; and (ii) we each retain the right to seek injunctive or other equitable relief from a court to prevent (or enjoin) the infringement or misappropriation of our intellectual property rights.</Copy>
      <Copy>(c) Conducting Arbitration and Arbitration Rules. The arbitration will be conducted by the Cayman International Arbitration Centre under its Arbitration Law, 2012 then in effect, except as modified by these Terms. The Arbitration Law, 2012 are available at https://legislation.gov.ky/cms/images/LEGISLATION/PRINCIPAL/2012/2012-0003/ArbitrationAct_Act%203%20of%202012.pdf. A party who wishes to start arbitration must submit a written Demand for Arbitration to Cayman International Arbitration Centre and give notice to the other party as specified in the Arbitration Law, 2012.</Copy>
      <Copy>(d) Arbitration Costs. Payment of all filing, administration and arbitrator fees will be governed by the Arbitration Law, 2012, and we won’t seek to recover the administration and arbitrator fees we are responsible for paying, unless the arbitrator finds your Dispute frivolous. If we prevail in arbitration we’ll pay all of our attorneys’ fees and costs and won’t seek to recover them from you. If you prevail in arbitration you will be entitled to an award of attorneys’ fees and expenses to the extent provided under applicable law.</Copy>
      <Copy>(e) Injunctive and Declaratory Relief. Except as provided in Section 15(b) above, the arbitrator shall determine all issues of liability on the merits of any claim asserted by either party and may award declaratory or injunctive relief only in favor of the individual party seeking relief and only to the extent necessary to provide relief warranted by that party’s individual claim. To the extent that you or we prevail on a claim and seek public injunctive relief (that is, injunctive relief that has the primary purpose and effect of prohibiting unlawful acts that threaten future injury to the public), the entitlement to and extent of such relief must be litigated in a civil court of competent jurisdiction and not in arbitration. The parties agree that litigation of any issues of public injunctive relief shall be stayed pending the outcome of the merits of any individual claims in arbitration.</Copy>
      <Copy>(f) Class Action Waiver. YOU AND KARAK FOUNDATION AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, if the parties’ Dispute is resolved through arbitration, the arbitrator may not consolidate another person’s claims with your claims, and may not otherwise preside over any form of a representative or class proceeding. If this specific provision is found to be unenforceable, then the entirety of this Dispute Resolution section shall be null and void.</Copy>
      <Copy>(g) Severability. With the exception of any of the provisions in Section 15(f) of these Terms (“Class Action Waiver”), if an arbitrator or court of competent jurisdiction decides that any part of these Terms is invalid or unenforceable, the other parts of these Terms will still apply.</Copy>
      <Copy>16. General Terms.</Copy>
      <Copy>(a) Reservation of Rights. Karak Foundation and its licensors exclusively own all right, title and interest in and to the Services, including all associated intellectual property rights. You acknowledge that the Services are protected by copyright, trademark, and other laws of the United Arab Emirates and foreign countries. You agree not to remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Services.</Copy>
      <Copy>(b) Entire Agreement. These Terms constitute the entire and exclusive understanding and agreement between Karak Foundation and you regarding the Services, and these Terms supersede and replace all prior oral or written understandings or agreements between Karak Foundation and you regarding the Services. If any provision of these Terms is held invalid or unenforceable by an arbitrator or a court of competent jurisdiction, that provision will be enforced to the maximum extent permissible and the other provisions of these Terms will remain in full force and effect. Except where provided by applicable law in your jurisdiction, you may not assign or transfer these Terms, by operation of law or otherwise, without Karak Foundation’s prior written consent. Any attempt by you to assign or transfer these Terms absent our consent or your statutory right, without such consent, will be null. Karak Foundation may freely assign or transfer these Terms without restriction. Subject to the foregoing, these Terms will bind and inure to the benefit of the parties, their successors and permitted assigns.</Copy>
      <Copy>(c) Future Updates. You acknowledge and agree that Karak Foundation may, at its sole discretion, make updates to its legal structure in the future. By accepting these Terms, you consent to and agree that any future terms of service or agreements will be binding upon you if Karak Foundation makes any updates to its legal structure in the future. We will make reasonable efforts to provide appropriate updates in a timely manner. You understand that such changes may result in alterations to the terms, including but not limited to the governing law, dispute resolution mechanisms, and other provisions. It is your responsibility to promptly review and accept the updated terms. Failure to accept the updated terms within the specified timeframe may result in the termination of your access to and use of the Interface.</Copy>
      <Copy>(d) Notices. Any notices or other communications provided by under these Terms will be given: (i) via email; or (ii) by posting to the Services. For notices made by email, the date of receipt will be deemed the date on which such notice is transmitted.</Copy>
      <Copy>(e) Waiver of Rights. Karak Foundation’s failure to enforce any right or provision of these Terms will not be considered a waiver of such right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of Karak Foundation. Except as expressly set forth in these Terms, the exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise.</Copy>
    </Container>
  );
}

export default TermsOfService;
