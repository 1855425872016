import {
  ChainName,
  EvmHypCollateralAdapter,
  EvmHypSyntheticAdapter,
  EvmNativeTokenAdapter,
  EvmTokenAdapter,
  IHypTokenAdapter,
  ITokenAdapter,
  MultiProtocolProvider,
} from '@hyperlane-xyz/sdk';
import { Address, ProtocolType, convertToProtocolAddress } from '@hyperlane-xyz/utils';

import { parseCaip2Id } from '../caip/chains';
import { getChainIdFromToken, isNativeToken, parseCaip19Id } from '../caip/tokens';
import { getMultiProvider } from '../wallet';

import { Route } from '../types';
import {
  isIbcToWarpRoute,
  isRouteFromCollateral,
  isRouteFromSynthetic,
  isRouteToCollateral,
  isRouteToSynthetic,
  isWarpRoute,
} from '../routes/utils';

export class AdapterFactory {
  static NativeAdapterFromChain(
    chainCaip2Id: ChainCaip2Id
  ): ITokenAdapter {
    const { protocol, reference: chainId } = parseCaip2Id(chainCaip2Id);
    const multiProvider = getMultiProvider();
    const chainName = multiProvider.getChainMetadata(chainId).name;
    if (protocol === ProtocolType.Ethereum) {
      return new EvmNativeTokenAdapter(chainName, multiProvider, {});
    } else {
      throw new Error(`Unsupported protocol: ${protocol}`);
    }
  }

  static NativeAdapterFromRoute(route: Route, source: 'origin' | 'destination'): ITokenAdapter {
    return AdapterFactory.NativeAdapterFromChain(
      source === 'origin' ? route.originCaip2Id : route.destCaip2Id
    );
  }

  static TokenAdapterFromAddress(tokenCaip19Id: TokenCaip19Id): ITokenAdapter {
    const { address, chainCaip2Id } = parseCaip19Id(tokenCaip19Id);
    const { protocol, reference: chainId } = parseCaip2Id(chainCaip2Id);
    const multiProvider = getMultiProvider();
    const chainName = multiProvider.getChainMetadata(chainId).name;
    const isNative = isNativeToken(tokenCaip19Id);
    if (protocol === ProtocolType.Ethereum) {
      return isNative
        ? new EvmNativeTokenAdapter(chainName, multiProvider, {})
        : new EvmTokenAdapter(chainName, multiProvider, { token: address });
    } else {
      throw new Error(`Unsupported protocol: ${protocol}`);
    }
  }

  static HypCollateralAdapterFromAddress(
    baseTokenCaip19Id: TokenCaip19Id,
    routerAddress: Address,
  ): IHypTokenAdapter {
    return AdapterFactory.selectHypAdapter(
      getChainIdFromToken(baseTokenCaip19Id),
      routerAddress,
      EvmHypCollateralAdapter,
    );
  }

  static HypSyntheticTokenAdapterFromAddress(
    chainCaip2Id: ChainCaip2Id,
    routerAddress: Address,
  ): IHypTokenAdapter {
    return AdapterFactory.selectHypAdapter(
      chainCaip2Id,
      routerAddress,
      EvmHypSyntheticAdapter,
    );
  }

  static HypTokenAdapterFromRouteOrigin(route: Route): IHypTokenAdapter {
    if (!isWarpRoute(route)) throw new Error('Route is not a hyp route');
    const { type, originCaip2Id, originRouterAddress } = route;
    if (isRouteFromCollateral(route)) {
      return AdapterFactory.selectHypAdapter(
        originCaip2Id,
        originRouterAddress,
        EvmHypCollateralAdapter,
      );
    } else if (isRouteFromSynthetic(route)) {
      return AdapterFactory.selectHypAdapter(
        originCaip2Id,
        originRouterAddress,
        EvmHypSyntheticAdapter,
      );
    } else {
      throw new Error(`Unsupported route type: ${type}`);
    }
  }

  static HypTokenAdapterFromRouteDest(route: Route): IHypTokenAdapter {
    if (!isWarpRoute(route) && !isIbcToWarpRoute(route))
      throw new Error('Route is not a hyp route');
    const { type, destCaip2Id, destRouterAddress } = route;
    if (isRouteToCollateral(route) || isIbcToWarpRoute(route)) {
      return AdapterFactory.selectHypAdapter(
        destCaip2Id,
        destRouterAddress,
        EvmHypCollateralAdapter,
      );
    } else if (isRouteToSynthetic(route)) {
      return AdapterFactory.selectHypAdapter(
        destCaip2Id,
        destRouterAddress,
        EvmHypSyntheticAdapter,
      );
    } else {
      throw new Error(`Unsupported route type: ${type}`);
    }
  }

  protected static selectHypAdapter(
    chainCaip2Id: ChainCaip2Id,
    routerAddress: Address,
    EvmAdapter: new (
      chainName: ChainName,
      mp: MultiProtocolProvider,
      addresses: { token: Address },
    ) => IHypTokenAdapter,
  ): IHypTokenAdapter {
    const { protocol, reference: chainId } = parseCaip2Id(chainCaip2Id);
    const multiProvider = getMultiProvider();
    const { name: chainName } = multiProvider.getChainMetadata(chainId);
    if (protocol === ProtocolType.Ethereum) {
      return new EvmAdapter(chainName, multiProvider, {
        token: convertToProtocolAddress(routerAddress, protocol),
      });
    } else {
      throw new Error(`Unsupported protocol: ${protocol}`);
    }
  }
}
