import { ethers } from 'ethers';
import {
  SignerOrProviderLike,
  CrossChainMessenger,
  ETHBridgeAdapter,
  StandardBridgeAdapter,
} from "@eth-optimism/sdk"; // eslint-disable-line

export const l1Contracts = {
  StateCommitmentChain: ethers.constants.AddressZero,
  CanonicalTransactionChain: ethers.constants.AddressZero,
  BondManager: ethers.constants.AddressZero,
  AddressManager: '0x96Be56ECcb7e60E656E135600233C42d809aFe01', // Lib_AddressManager.json
  L1CrossDomainMessenger: '0xa3B90CD0A14AB26398626C578e0bF0145A169B7d', // Proxy__OVM_L1CrossDomainMessenger.json
  L1StandardBridge: '0x0094BF370D46d3E318127Eed783Bec46414e6951', // Proxy__OVM_L1StandardBridge.json
  OptimismPortal: '0xD84600Faf06c630C33809511Dd7481E7f11baC6A', // OptimismPortalProxy.json
  L2OutputOracle: '0x758502f01B613287731c0788f1fE4D5C4C19B79B', // L2OutputOracleProxy.json
};

const bridges = {
  Standard: {
    l1Bridge: l1Contracts.L1StandardBridge,
    l2Bridge: '0x4200000000000000000000000000000000000010',
    Adapter: StandardBridgeAdapter,
  },
  ETH: {
    l1Bridge: l1Contracts.L1StandardBridge,
    l2Bridge: '0x4200000000000000000000000000000000000010',
    Adapter: ETHBridgeAdapter,
  },
};

export const erc20Addrs = {
  l1: '0xbaf289a8c7A9809E13ac81dC073BD10E051de1Df', // USDC goerli
  l2: '0xb2cA8e09e919E91F1bc50Bf940b16C7b75c72bec', // USDC karak l2
};

export const createCrossChainMessenger = async (
  l1Signer: SignerOrProviderLike,
  l2Signer: SignerOrProviderLike
) => {
  const crossChainMessenger = new CrossChainMessenger({
    bedrock: true,
    l1ChainId: 5, // Goerli eth value chain id - TODO chainge to 
    l2ChainId: 2511, // Goerli karak value chain id
    l1SignerOrProvider: l1Signer,
    l2SignerOrProvider: l2Signer,
    contracts: {
      l1: l1Contracts,
    },
    bridges,
  });

  return crossChainMessenger;
};
