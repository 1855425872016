import React, { useState, useEffect } from 'react';
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { DepositWithdraw } from './DepositWithdraw';
import { RoutesMap } from '../../../hyperlane/types';
// import { fetchTokenRoutes } from '../../../hyperlane/routes/token-routes';
import { useL1Balances, useL2Balances } from '../../../hooks/userBalances';
import { fetchArbDestinationBalances, fetchKarakDestinationBalances, fetchOPDestinationBalances, KarakBridgeCollateral } from '../../../utils/collateralBalances';
import { Outgoing } from './Outgoing';
import { ButtonConnect } from '../ConnectButton/ConnectButton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 7rem;
`;

const Heading = styled.h1`
  text-align: center;
  color: #303030;
  font-size: 2.5rem;
  font-family: Gilroy;
  font-weight: 800;
  letter-spacing: -0.96px;
  margin-bottom: 1rem;
`;

const OrderboxContainer = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);
  max-width: 27rem;
  width: 46%;
  margin: 1.5rem auto auto auto;
  height: fit-content !important;

  @media only screen and (max-width: 1149px) {
    width: 100%;
  }
`;

const CustomTab = styled(Tab)`
  color: #B7B7B7;
  text-align: center;
  font-weight: 600;
  width: 100%;

  &:hover {
    filter: brightness(80%);
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;

    &:hover {
      filter: brightness(100%) !important;
    }
  }

  @media only screen and (max-width: 499px) {
    &:hover {
      filter: brightness(100%);
    }
  }
`;

const Line = styled.div`
  border-top: 0.2px solid #d65A00;
`;

interface Props {
  setPage: React.Dispatch<React.SetStateAction<boolean[]>>;
  bridgeOrigin: string;
}

export const Bridge: React.FC<Props> = () => {
  const [tokenRoutes, setTokenRoutes] = useState<RoutesMap>(); // eslint-disable-line
  const [bridgeCollateral, setBridgeCollateral] = useState<KarakBridgeCollateral>({
    karak: { ethBalance: '0', usdcBalance: '0' },
    optimism: { ethBalance: '0', usdcBalance: '0' },
    arbitrum: { ethBalance: '0', usdcBalance: '0' },
  });
  const l1Balances = useL1Balances();
  const l2Balances = useL2Balances();

  // useEffect(() => { // TODO: uncomment once we enable op/arb again
  //   const getTokenRoutes = async () => {
  //     const tokenRoutesMap = await fetchTokenRoutes();
  //     setTokenRoutes(tokenRoutesMap);
  //   }
  //   getTokenRoutes();
  // }, []);

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  useEffect(() => {
    const getCollateralBalances = async () => {
      if (!tokenRoutes) return;
      const karak = await fetchKarakDestinationBalances(tokenRoutes);
      const optimism = await fetchOPDestinationBalances(tokenRoutes);
      const arbitrum = await fetchArbDestinationBalances(tokenRoutes);
      setBridgeCollateral({
        karak,
        optimism,
        arbitrum
      });
    }
    getCollateralBalances();
  }, [tokenRoutes]);

  return (
    <Container>
      <Heading>Bridge Assets</Heading>
      <ButtonConnect />
      <OrderboxContainer>
        <Tabs variant="unstyled">
          <TabList>
            <CustomTab
              fontSize="1.rem"
              padding="0.75rem 0rem"
              borderRadius="5px 0px 0px 0px !important"
              _selected={{  bg: "#FFF4ED", color: "var(--components-primary-orange)" }}
            >
              DEPOSIT
            </CustomTab>
            <CustomTab
              fontSize="1rem"
              padding="0.75rem 0rem"
              borderRadius="0px !important"
              _selected={{ bg: "#FFF4ED", color: "var(--components-primary-orange)" }}
            >
              WITHDRAW
            </CustomTab>
            <CustomTab
              fontSize="1rem"
              padding="0.75rem 0rem"
              borderRadius="0px 5px 0px 0px !important"
              _selected={{ bg: "#FFF4ED", color: "var(--components-primary-orange)" }}
            >
              OUTGOING
            </CustomTab>
          </TabList>
          <Line />
          <TabPanels>
            <TabPanel>
              <DepositWithdraw
                action='deposit'
                tokenRoutes={tokenRoutes}
                l1Balances={l1Balances}
                l2Balances={l2Balances}
                bridgeCollateral={bridgeCollateral}
              />
            </TabPanel>
            <TabPanel>
              <DepositWithdraw
                action='withdraw'
                tokenRoutes={tokenRoutes}
                l1Balances={l1Balances}
                l2Balances={l2Balances}
                bridgeCollateral={bridgeCollateral}
              />
            </TabPanel>
            <TabPanel>
              <Outgoing />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </OrderboxContainer>
    </Container>
  );
} 
