import React from 'react';
import styled from 'styled-components';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const Heading = styled.p`
  font-weight: bold;
  font-size: var(--p-font-size);
`;

const Text = styled.p`
  font-size: var(--p-font-size);
  margin-bottom: 15px;
`;

const List = styled.ul`
  padding: 1rem;
`;

const Content = styled(ModalContent)`
  max-width: 500px !important;
  background-color: #1F0D00 !important;
  margin: auto;
  border-radius: 1rem;
  padding: 1rem;

  @media only screen and (max-width: 796px) {
    max-width: 300px !important;
  }
`;

export const TOSModal: React.FC<Props> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} preserveScrollBarGap>
      <ModalOverlay bg="var(--chakra-colors-blackAlpha-800)" zIndex={2147483647}>
        <Content>
          <ModalHeader textColor="white" fontWeight={"bold"}>
            Terms and Conditions
          </ModalHeader>
          <ModalCloseButton
            style={{ color: 'white', marginTop: -30, marginLeft: 'auto', marginBottom: 20 }}
            _focus={{ outline: 'none' }}
            _hover={{ filter: 'brightness(65%)' }}
            size="sm"
          />
          <ModalBody
            maxHeight="300px"
            overflowY="scroll"
            mb={5}
            bgColor="white"
            mx={5}
            borderRadius={5}
            padding="1rem"
          >
            <Text style={{ marginBottom: '0px' }}>
              XP Terms of Service
            </Text>
            <Text>Last Updated: February 2024</Text>
            <Text>
              Karak XP is experimental and is provided to you on an "AS IS" and "AS AVAILABLE" basis.
              Your participation is entirely voluntary, any use will be at your own risk, and without warranties
              by Karak or Karak Network of any kind.
            </Text>
            <Heading>Notice on Prohibited Use – US and Other Restricted Persons</Heading>
            <List>
              <li>
                KARAK.NETWORK and any related services are not offered to and may not be used by persons or
                entities who reside in, are located in, are incorporated in, or have a registered office in the
                United States of America (collectively, “US Persons”) or in any restricted territory, as defined
                below (each such person or entity from a restricted territory, a “Restricted Person”). We do not make
                exceptions. Therefore, if you are a US Person or other Restricted Person, then do not attempt to use
                KARAK.NETWORK or any related services. Use of a Virtual Private Network (“VPN”) or any other similar
                means intended to circumvent the restrictions set forth herein is prohibited.
              </li>
              <li>
                Karak may restrict services, including in certain jurisdictions due to applicable laws, regulations
                or business considerations at its sole discretion. Karak will not be liable for any loss or damage
                resulting from the temporary or permanent loss of use to any service or related services.
              </li>
              <li>
                These terms and conditions, together with any documents or terms expressly incorporated by reference,
                which includes any other terms and conditions or other agreement that Karak XP (“Karak XP” “Karak” “we” “us” and “our”)
                posts publicly or makes available to you or the company or other legal entity you represent (“you” or “your”)
                (collectively, these “Terms”), are entered into between Karak and YOU concerning your use of, and access to, Karak’s
                websites, including karak.network, mobile applications, all associated sites and interfaces linked thereto by Karak or its
                affiliates, application programming interfaces (“APIs”) and source code (collectively with any materials and services available
                therein, and successor website(s) or application(s) thereto, the “Site”).
              </li>
            </List>
            <Heading>Please read these terms carefully</Heading>
            <List>
              <li>As these Terms govern your use of the Site and access to any and all functionality provided through or facilitated by the Site (collectively, the “Services”). These Terms expressly cover your rights and obligations, and our disclaimers and limitations of legal liability, relating to your use of, and access to, the Site and the Services.</li>
            </List>
            <Heading>Terms of Use for XP System</Heading>
            <List>
              <li>1.1. This XP system (“XP System”) is operated by Andalusia Labs Ltd. (“we”, “us”, “our”). The XP System is applicable to all users (“you”, “your”) of the Karak Blockchain (“Network”).</li>
              <li>1.2. By using the XP System, you agree to be bound by these terms and conditions (“Terms”). If you do not agree to these Terms, you should not use the XP System.</li>
            </List>
            <Heading>XP System</Heading>
            <List>
              <li>2.1. The XP System is a mechanism that allows you to accumulate XP (“XP”) based on your usage and interaction with the Network and services provided therein. The number of XP earned may vary depending on the nature of your interaction with the Network.</li>
              <li>2.2. We reserve the right to determine, in our sole discretion, the number of XP awarded for any given interaction.</li>
            </List>
            <Heading>Changes to XP Calculation</Heading>
            <List>
              <li>3.1. We expressly reserve the right, in our sole and absolute discretion, to modify, alter, or change the calculation or valuation of XP at any time and for any reason without prior notice.</li>
              <li>3.2. Such modifications, alterations, or changes may include, but are not limited to, increasing or decreasing the number of XP awarded for a specific interaction, changing the activities for which XP may be awarded, or imposing limits on the accumulation or redemption of XP.</li>
            </List>
            <Heading>No Guarantee</Heading>
            <List>
              <li>4.1. We do not guarantee the availability of any particular redemption level or reward. The amount of XP required to redeem any reward may be substantially increased, any reward may be withdrawn, and restrictions on any reward or its redemption may be imposed at any time.</li>
            </List>
            <Heading>No Monetary Value</Heading>
            <List>
              <li>5.1. XP have no cash value, are not exchangeable for cash, and are not transferable. The sale, barter, or other disposal of XP, other than by us, is expressly prohibited.</li>
            </List>
            <Heading>Acceptance of These Terms</Heading>
            <List>
              <li>6.1. By using the XP System, you acknowledge that you have read and understood these Terms and agree to be bound by them. If you do not agree to these Terms, please discontinue your use of the XP System immediately.</li>
            </List>
            <Heading>Contact Us</Heading>
            <List>
              <li>7.1. If you have any questions regarding these Terms or the XP System, please contact us.</li>
            </List>
          </ModalBody>
        </Content>
      </ModalOverlay>
    </Modal>
  );
};
