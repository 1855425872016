import { useEffect } from "react";
import * as d3 from "d3";
import Globe from 'globe.gl';


const GlobeAnimation = () => {
  useEffect(() => {
    const globeContainer = document.getElementById('globe');
    if (globeContainer) {
      const map = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAAaADAAQAAAABAAAAAQAAAAD5Ip3+AAAAC0lEQVQIHWP4DwQACfsD/Qy7W+cAAAAASUVORK5CYII=",
      landCheckUrl = "https://github.githubassets.com/images/modules/site/home/globe/map.png",
      landCheckImg = new Image(),
      DEG2RAD = Math.PI / 180,
      random_sample = (obj, n) => d3.shuffle(obj.slice()).slice(0, n),
      N = 10,
    
      world = Globe({ waitForGlobeReady: true })
        .globeImageUrl(map)
        .backgroundColor('rgba(0, 0, 0, 0)')
        .showAtmosphere(false)
        .atmosphereColor('white')
        .atmosphereAltitude(0.3)
        .width(500)
        .height(650)
        (globeContainer);
    
    
      landCheckImg.src = landCheckUrl;
      landCheckImg.crossOrigin = "Anonymous";
      landCheckImg.onload = () => {
        landDots(25, 200, landCheckImg);
      }
    
      world.pointOfView({lat: 15});
      world.controls().autoRotate = true;
      world.controls().autoRotateSpeed = 0.85;
      world.controls().enabled = false;
    
      window.addEventListener('resize', (event) => { // eslint-disable-line
        if (window.innerWidth <= 1211) {
          world.width(350)
          world.height(500)
        } else {
          world.width(500)
          world.height(650)
        }
      });
    
      function landDots(globeRad, rows, image) {
        const d = getImageData(image);
        const dots = [];

        for (let lat = -90; lat <= 90; lat += 180 / rows) {
          const radius = Math.cos(Math.abs(lat) * DEG2RAD) * globeRad;
          const circum = radius * Math.PI * 2 * 2;
          for (let r = 0; r < circum; r++) {
            const lng = 360 * r / circum - 180;
            if (!visibilityForCoordinate(lng, lat, d))
              continue;
            dots.push({lat: lat, lng: lng});
          }
        }
        world
          .pointsData(dots)
          .pointColor(() => '#DF5E00')
          .pointRadius(0.25)
          .pointResolution(5)
          .pointAltitude(0)
          .pointsMerge(true)
          // arcs
          .arcColor(() => '#DF5E00')
          .arcStroke(0.5)
          .arcDashInitialGap(1)
          .arcDashLength(1)
          .arcDashGap(1)
          .arcDashAnimateTime(1000)
          .arcsTransitionDuration(0)
          // labels
          .labelText(d => "")
          .labelColor(() => t => `rgba(51,5,141,${1-t})`)
          .labelDotRadius(0.3)
          .labelAltitude(0.002)
          .labelsTransitionDuration(250)
          // rings
          .ringColor(() => '#0000ff')
          .ringColor(() => t => `rgba(51,5,141,${1-t})`)
          .ringMaxRadius(4)
          .ringPropagationSpeed(4)
          .ringRepeatPeriod(0);
      
        setInterval(() => {
          let c10 = random_sample(dots, N*2),
            c = [...Array(N).keys()].map((d, i) => ({
              startLat: c10[i].lat,
              startLng: c10[i].lng,
              endLat: c10[i+N].lat,
              endLng: c10[i+N].lng
            })),
            r = [...Array(N).keys()].map((d, i) => ({
              lat: c10[i+N].lat,
              lng: c10[i+N].lng
            }));

          world.arcsData(c);
          
          setTimeout(() => {
            world.ringsData(r);
          }, 1000);
        }, 2000);
      
      }
    
      function visibilityForCoordinate(lng, lat, data) {
        const i = 4 * data.width,
          r = parseInt((lng + 180) / 360 * data.width + .5),
          a = data.height - parseInt((lat + 90) / 180 * data.height - .5),
          s = parseInt(i * (a - 1) + 4 * r) + 3;
        return data.data[s] > 90;
      }
    
      function getImageData(img) {
        const el = document.createElement("canvas").getContext("2d");
        console.log(img.width, img.height);
        return el.canvas.width = img.width, // eslint-disable-line
          el.canvas.height = img.height, 
          el.drawImage(img, 0, 0, img.width, img.height), 
          el.getImageData(0, 0, img.width, img.height);
      }
    }
  }, []);

  return (
    <div id="globe" style={{ pointerEvents: "none" }} />
  );
}

export default GlobeAnimation;