import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useAccount } from 'wagmi';
import { Logger } from '../utils/logger';
import { getUserKarakMainnetBalances } from '../utils/userBalances';

interface UserBalances {
  ethBalance: string | null;
  usdcBalance: string | null;
  rswEthBalance: string | null;
}

export type KarakBalancesContextValue = {
  karakBalances: UserBalances;
  noXP: boolean | undefined;
  refreshKarakBalances(): void;
};

const KarakBalancesContext = React.createContext<KarakBalancesContextValue>({
  karakBalances: { ethBalance: null, usdcBalance: null, rswEthBalance: null },
  noXP: undefined,
  refreshKarakBalances() {},
});
  
export function useKarakBalances() {
  return useContext(KarakBalancesContext);
}

export const KarakBalancesProvider: React.FC<any> = ({ children }) => {
  const [refreshBeat, setRefreshBeat] = useState(BigInt(0));
  const [noXP, setNoXP] = useState<boolean>();
  const [karakBalances, setKarakBalances] = useState<UserBalances>({
    ethBalance: null,
    usdcBalance: null,
    rswEthBalance: null,
  });
  const { address } = useAccount();
    
  useEffect(() => {
    const fetchKarakBalances = async () => {
      if (!address) return;
      try {
        const { ethBalance, usdcBalance, rswEthBalance } = await getUserKarakMainnetBalances(address);
        if (ethBalance === '0' && usdcBalance === '0' && rswEthBalance === '0') {
          setNoXP(true);
        } else {
          setNoXP(false);
        }
        setKarakBalances({ ethBalance, usdcBalance, rswEthBalance });
      } catch (error) {
        Logger.error('Error fetching Karak balances:', error);
      }
    }
    
    fetchKarakBalances();
  }, [address, refreshBeat]);
  
  const refresh = useRef(() => {
    setRefreshBeat((p) => p + BigInt(1));
  });
  
  const value = useMemo(
    () => ({
      karakBalances,
      noXP,
      refreshKarakBalances: refresh.current,
    }),
    [karakBalances, noXP]
  );
  
  return (
    <KarakBalancesContext.Provider value={value}>
      {children}
    </KarakBalancesContext.Provider>
  );
};
