import { useToast, UseToastOptions } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { Time } from "../utils/time";

type NodeOrString = string | React.ReactNode;

export interface Notifications {
    success(title: NodeOrString, message?: NodeOrString): void;
    error(title: NodeOrString, message: NodeOrString): void;
    info(title: NodeOrString, message: NodeOrString): void;
    warn(title: NodeOrString, message: NodeOrString): void;
}

const BASE_TOAST_OPTIONS: UseToastOptions = {
    position: "top-right",
    duration: Time.fromSeconds(10).toMilliseconds(),
    variant: "solid",
    isClosable: true,
};

const SUCCESS_TOAST_OPTIONS: UseToastOptions = {
    ...BASE_TOAST_OPTIONS,
    status: "success",
};

const ERROR_TOAST_OPTIONS: UseToastOptions = {
    ...BASE_TOAST_OPTIONS,
    status: "error",
};

const INFO_TOAST_OPTIONS: UseToastOptions = {
    ...BASE_TOAST_OPTIONS,
    status: "info",
};
const WARN_TOAST_OPTIONS: UseToastOptions = {
    ...BASE_TOAST_OPTIONS,
    status: "warning",
};

export function useNotifications() {
    const toast = useToast();
    const notifications: Notifications = useMemo(
        () => ({
            success(title, message) {
                toast({
                    ...SUCCESS_TOAST_OPTIONS,
                    title,
                    description: message,
                });
            },
            error(title, message) {
                toast({
                    ...ERROR_TOAST_OPTIONS,
                    title,
                    description: message,
                });
            },
            info(title, message) {
                toast({
                    ...INFO_TOAST_OPTIONS,
                    title,
                    description: message,
                });
            },
            warn(title, message) {
                toast({
                    ...WARN_TOAST_OPTIONS,
                    title,
                    description: message,
                });
            },
        }),
        [toast]
    );

    return notifications;
}
