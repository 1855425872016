import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Table, Td, Th, Tr, Box, Thead, Tbody, Button, Spinner } from '@chakra-ui/react';
import { WithdrawBridgeMessage, WithdrawStatus, proveUserWithdrawal, finishUserWithdrawal } from '../../../actions/offboarding-withdraw';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';
import { Logger } from '../../../utils/logger';
import { useNotifications } from '../../../utils/notifications';
// import { FixedPointNumber } from '../../../utils/fixedPoint';
import { decimalFormat, mapRawAmountToUi } from '../../../utils/helpers';
import { PaginationHOC } from './PaginationHOC';
import { getAddress } from 'viem';

const STable = styled(Table)`
  border-collapse: separate !important;
  border-spacing: 0 1rem !important;
  table-layout:fixed;

  tr td:first-child {
    border-top-left-radius: 8px;
  }
  tr td:last-child {
    border-top-right-radius: 8px;
  }
  tr td:first-child {
    border-bottom-left-radius: 8px;
  }
  tr td:last-child {
    border-bottom-right-radius: 8px;
  }
`;

const STr = styled(Tr)`
  height: 5rem;
`;

const STh = styled(Th)`
  font-weight: 700;
  color: #303030;
`;

const STd = styled(Td)`
  color: #303030;
  font-weight: 600;
  font-size: 0.7rem;
  border-bottom: 1px solid #D65A00;
  border-top: 1px solid #D65A00;
`;

interface Props {
  items: WithdrawBridgeMessage[];
}

const tokenAddressToInfoMap: {[addr: string]: { decimals: number; symbol: string }} = {
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48': { // USDC address eth mainnet
    decimals: 6,
    symbol: 'USDC',
  },
  '0x0000000000000000000000000000000000000000': { // ETH address on eth mainnet
    decimals: 18,
    symbol: 'ETH',
  },
  '0xFAe103DC9cf190eD75350761e95403b7b8aFa6c0': { // rswETH address on eth mainnet
    decimals: 18,
    symbol: 'rswETH',
  }
};

export const WithdrawTable = PaginationHOC<Props> (({ items }) => {
  const [loading, setLoading] = useState(false);
  const [currentTxHash, setCurrentTxHash] = useState('');
  const { switchNetworkAsync } = useSwitchNetwork();
  const { chain } = useNetwork();
  const { address } = useAccount();
  const notifications = useNotifications();

  const buttonAction = (status: WithdrawStatus) => {
    if (status === 'PENDING_IN_CHALLENGE_PERIOD' || status === 'READY_TO_FINALIZE') return 'FINISH';
    return 'PROVE';
  }

  const withdrawalAction = useCallback(async (txHash: string, status: WithdrawStatus): Promise<void> => {
    try {
      setLoading(true);
      if (status === 'READY_TO_PROVE') {
        await proveUserWithdrawal(txHash, switchNetworkAsync, chain?.id, notifications);
      } else {
        await finishUserWithdrawal(txHash, switchNetworkAsync, chain?.id, notifications);
      }
      setLoading(false);
      setCurrentTxHash('');
    } catch (e) {
      setLoading(false);
      setCurrentTxHash('');
      Logger.error('Error:', e);
    }
  }, [switchNetworkAsync, chain?.id, notifications]);

  return (
    <Box overflowX="hidden">
      <STable variant="unstyled">
        <Thead>
          <Tr>
            <STh>AMOUNT</STh>
            <STh>STATUS</STh>
          </Tr>
        </Thead>
        {items.filter((w) => w.l1Token in tokenAddressToInfoMap).map((withdrawal: WithdrawBridgeMessage, index) => (
          <Tbody key={index}>
            <STr>
              <STd borderLeft="1px solid #D65A00">
                {/* {FixedPointNumber.fromRaw(
                  BigInt(withdrawal.amount.toString()),
                  tokenAddressToInfoMap[withdrawal.l1Token].decimals
                  ).toString()
                } */}
                {decimalFormat(mapRawAmountToUi(
                  withdrawal.amount.toString(),
                  tokenAddressToInfoMap[withdrawal.l1Token].decimals
                ))}
                {' '}{tokenAddressToInfoMap[withdrawal.l1Token].symbol}
              </STd>
              <STd>
                {withdrawal.status.includes('PENDING') ? 'PENDING' : 'READY'}
              </STd>
              <STd borderRight="1px solid #D65A00">
                <Button
                  isDisabled={
                    withdrawal.status.includes('PENDING') ||
                    address === getAddress('0x6e5A4296722608Ba6d52f94e21f66310c36668df')
                  }
                  _hover={{
                    textDecoration: 'none',
                    filter: 'brightness(85%)',
                  }}
                  _active={{ color: 'transparent' }}
                  _focus={{ outline: 'none', boxShadow: 'none' }}
                  px="var(--button-px)"
                  py="var(--button-py)"
                  textColor="white"
                  background="var(--components-primary-orange)"
                  borderRadius="5px"
                  fontSize="var(--p2-font-size)"
                  onClick={() => {
                    setCurrentTxHash(withdrawal.transactionHash);
                    withdrawalAction(withdrawal.transactionHash, withdrawal.status);
                  }}
                  width="100%"
                >
                  {
                    currentTxHash === withdrawal.transactionHash && loading
                      ? <Spinner />
                      : <> {buttonAction(withdrawal.status)} </>
                  }
                </Button>
              </STd>
            </STr>
          </Tbody>
        ))}
      </STable>
    </Box>
  );
});
