import React from "react";
import styled from "styled-components";
import { Button, Link } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import innovation from "../../assets/infinite-innovation.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6rem;
`;

const HeadingTitle = styled.h1`
  text-align: center;
  color: #303030;
  font-size: 2.5rem;
  font-family: Gilroy;
  font-weight: 800;
  line-height: 1.15;
  letter-spacing: -0.96px;
  z-index: 999;
  margin-bottom: 1rem;
`;

const Text = styled.p`
  text-align: center;
  color: #303030;
  font-family: Nunito Sans;
  font-size: var(--p-font-size);
  z-index: 999;
  margin-bottom: 1.5rem;
  width: 50rem;

  @media only screen and (max-width: 867px) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 40rem;
`;

const InfiniteInnovation = () => {
  return (
    <Container>
      <HeadingTitle>Infinite innovation</HeadingTitle>
      <Text>
        Karak fosters an environment of infinite innovation by providing developers with universal access
        to trust networks, which enables an endless creation of novel applications, protocols, and services.
        Get in touch with the team today to start building.
      </Text>
      <Button
        as={Link}
        href="https://airtable.com/appomORhKwfeQrCIk/shrla3jWOjs7fId8j"
        isExternal
        _hover={{textDecoration: 'none', filter: 'brightness(95%)'}}
        _active={{color: 'transparent'}}
        _focus={{outline: 'none', boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.10)'}}
        px="var(--button-px)"
        py="var(--button-py)"
        textColor="var(--components-primary-orange)"
        target="_new"
        background="white"
        boxShadow="0px 4px 12px 0px rgba(0, 0, 0, 0.10)"
        fontSize="0.9rem"
        borderRadius="5px"
        width="10rem"
        fontFamily="Nunito Sans"
        fontWeight="800"
        marginBottom={"4rem"}
      >
        Get in touch
        <ArrowForwardIcon boxSize={5} ml={1} color="var(--components-primary-orange)" />
      </Button>
      <Image src={innovation} alt="infinite-innovation-layers" />
    </Container>
  );
}

export default InfiniteInnovation;