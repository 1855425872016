import React from "react";
import { Text, Spinner } from "@chakra-ui/react";
import { useOffboardingWithdrawalsContext } from "../../../contexts/OffboardingWithdrawals";
import { WithdrawTable } from "./WithdrawTable";
import styled from "styled-components";
import { useAccount } from "wagmi";
import { ToolTip } from "../../ToolTip/ToolTip";

const Heading = styled.p`
  font-size: 1.15rem;
  color: #303030;
  font-weight: 700;
  font-family: Nunito Sans;
`;

export const Outgoing: React.FC = () => {
  const { address } = useAccount();
  const offboardingWithdrawals = useOffboardingWithdrawalsContext();

  if (!address) {
    return (
      <div>
        <Heading>Outgoing withdrawals</Heading>
        <Text
          color="#303030"
          fontSize="0.95rem"
          my={'1rem'}
          fontFamily="Nunito Sans"
        >
          Please connect your wallet.
        </Text>
      </div>
    )
  }

  return (
    <div>
      <Heading>
        Outgoing withdrawals
        <ToolTip
          tooltipText={'Track the status of your pending withdrawals here. Please note that withdrawals take 3 days to finish AFTER proving your withdraw.'}
          width='100%'
        />
        </Heading>
      {offboardingWithdrawals.map((d) => d.pendingWithdrawals).isNone() ? (
        <div>
          <Spinner
            display={'flex'}
            thickness='4px'
            speed='0.55s'
            color='var(--components-primary-orange)'
            size='xl'
            margin="1.5rem auto 1.5rem auto"
          />
        </div>
      ) : (
        <div>
          {offboardingWithdrawals.map((d) => d.pendingWithdrawals).getOrElse(Array).length === 0 ? (
            <Text
              color="#303030"
              fontSize="0.95rem"
              my={'1rem'}
              fontFamily="Nunito Sans"
            >
              You currently have no outgoing withdrawals at this time.
            </Text>
          ) : (
            <WithdrawTable items={offboardingWithdrawals.map((d) => d.pendingWithdrawals).getOrElse(Array)} />
          )}
        </div>
      )}
    </div>
  );
}
