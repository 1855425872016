import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { WagmiProvider } from './contexts/WagmiConfig';
import { KarakBalancesProvider } from './contexts/KarakBalances';
import { OffboardingWithdrawalsProvider } from './contexts/OffboardingWithdrawals';

ReactDOM.render(
  <React.StrictMode>
    <WagmiProvider>
      <KarakBalancesProvider>
        <OffboardingWithdrawalsProvider>
          <App />
        </OffboardingWithdrawalsProvider>
      </KarakBalancesProvider>
    </WagmiProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
