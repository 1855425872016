import { ethers } from "ethers";
import { VStack, Text } from "@chakra-ui/react";
import { getEthersSigner } from "../utils/ethers";
import { Logger } from "../utils/logger";
import { createCrossChainMessenger, erc20Addrs, l1Contracts } from "../utils/mainnetCrossChainMessenger";
import { FixedPointNumber } from "../utils/fixedPoint";
import { Maybe } from "../utils/maybe";
import { MessageStatus } from "@eth-optimism/sdk";
import { SwitchNetworkAsync } from "./shared";
import { makeTxExplorerLink } from "../utils/txNotifications";
import { mainnet } from "viem/chains";
import { Notifications } from "../utils/notifications";
import { TxExplorerLink } from "../components/TxExplorer/TxExplorerLink";
import { L2_GAS_LIMIT, ToastTitles } from "../constants/constants";
import { MAINNET_KARAK_RPC } from "../contexts/WagmiConfig";
import { erc20ABI } from "wagmi";
import { L1StandardBridgeAbi } from "../constants/abis/L1StandardBridge";
import { getCaip2Id } from "../hyperlane/caip/chains";
import { AssetNamespace, getCaip19Id } from "../hyperlane/caip/tokens";
import { ProtocolType } from "@hyperlane-xyz/utils";
import { executeTransfer } from "../hyperlane/transfer/token-transfer";
import { RoutesMap } from "../hyperlane/types";
import { findCollateralTokenAddressByChainId } from "../hyperlane/tokens/metadata";
import { zeroAddress } from "viem";

export async function performHyperlaneTransfer(
  userAddress: string,
  originChainId: number,
  destinationChaindId: number,
  currentChainId: number,
  tokenRoutes: RoutesMap,
  amount: Maybe<string>,
  switchNetworkAsync: SwitchNetworkAsync,
  notifications: Notifications,
  asset: string,
) {
  const originCaip2Id = getCaip2Id(ProtocolType.Ethereum, originChainId);
  const destinationCaip2Id = getCaip2Id(ProtocolType.Ethereum, destinationChaindId);
  const activeChainCaip2Id = getCaip2Id(ProtocolType.Ethereum, currentChainId);
  const tokenCaip19Id = asset === 'ETH'
    ? getCaip19Id(originCaip2Id, AssetNamespace.native, zeroAddress)
    : getCaip19Id(originCaip2Id, AssetNamespace.erc20, findCollateralTokenAddressByChainId(originChainId));
  await executeTransfer({
    values: {
      originCaip2Id,
      destinationCaip2Id,
      recipientAddress: userAddress,
      tokenCaip19Id,
      amount: amount.string(),
    },
    tokenRoutes,
    userAddress,
    activeChainCaip2Id,
    notifications,
    switchNetworkAsync,
  });
}

export async function performDepositEth(
  amount: Maybe<string>,
  switchNetworkAsync: SwitchNetworkAsync,
  currentChainId: number | undefined,
  notifications: Notifications,
): Promise<void> {
  if (currentChainId !== 1) { // if current connected network is not eth mainnet, trigger switch to eth mainnet (in this case eth mainnet is L1)
    await switchNetworkAsync?.(1);
  }

  const l1Signer = await getEthersSigner({ chainId: 1 });
  const l2Signer = new ethers.providers.JsonRpcProvider(MAINNET_KARAK_RPC);

  if (!l1Signer || !l2Signer) {
    throw new Error('Data still loading - l1 or l2 signer still missing');
  }

  Logger.info('Deposit ETH');

  const start = new Date();

  const l1StandardBridge = new ethers.Contract(l1Contracts.L1StandardBridge, L1StandardBridgeAbi, l1Signer);

  const crossChainMessenger = await createCrossChainMessenger(
    l1Signer,
    l2Signer
  );

  //const gasLimit = 500_000;
  const response = await l1StandardBridge.depositETH(
    L2_GAS_LIMIT,
    '0x',
    { value: FixedPointNumber.fromDecimal(amount.string(), 18).toRawString() }
  );
  Logger.info(`Transaction hash (on L1): ${response.hash}`);

  notifications.info(
    'Processing transaction...',
    <VStack alignItems="flex-start">
      <Text>Please be patient as your transaction</Text>
      <Text marginTop="-0.25rem !important">may take some time to process.</Text>
    </VStack>
  );

  await response.wait(1);

  Logger.info('Waiting for status to change to RELAYED');
  Logger.info(
    `Time so far ${(new Date().getTime() - start.getTime()) / 1000} seconds`
  );
  await crossChainMessenger.waitForMessageStatus(
    response.hash,
    MessageStatus.RELAYED
  );
  Logger.info('After wait for message status relayed');

  Logger.info(
    `depositETH took ${(new Date().getTime() - start.getTime()) / 1000}
    seconds`
  );
  Logger.info(`Bridged eth tx hash: ${response.hash}`);
  const txHashLink = makeTxExplorerLink(mainnet, response.hash); // pass in chain dynamically later on
  notifications.success(
    ToastTitles.BridgeDepositEth.successMsg,
    txHashLink
      .map((l) => (
        <TxExplorerLink
          explorerName={l.name}
          explorerUrl={l.url}
        />
      ))
      .getOrElse(() => <></>)
  );
}

export async function performDepositERC20(
  amount: Maybe<string>,
  erc20Asset: string,
  switchNetworkAsync: SwitchNetworkAsync,
  currentChainId: number | undefined,
  notifications: Notifications,
): Promise<void> {
  if (currentChainId !== 1) { // if current connected network is not eth mainnet, trigger switch to eth mainnet (in this case eth mainnet is L1)
    await switchNetworkAsync?.(1);
  }

  const l1Signer = await getEthersSigner({ chainId: 1 });
  const l2Signer = new ethers.providers.JsonRpcProvider(MAINNET_KARAK_RPC);

  if (!l1Signer || !l2Signer) {
    throw new Error('Data still loading - l1 or l2 signer still missing');
  }

  Logger.info('Deposit ERC20');

  const start = new Date();

  const l1StandardBridge = new ethers.Contract(l1Contracts.L1StandardBridge, L1StandardBridgeAbi, l1Signer);

  const crossChainMessenger = await createCrossChainMessenger(
    l1Signer,
    l2Signer
  );

  const { currAllowance, amountRaw, tokenDecimals } = await getTokenDetails(l1Signer, amount, erc20Asset);

  if (currAllowance < amountRaw) {
    const allowanceTxResponse = await crossChainMessenger.approveERC20(
      erc20Addrs[erc20Asset].l1,
      erc20Addrs[erc20Asset].l2,
      amountRaw
    );
    await allowanceTxResponse.wait();
    Logger.info(`Allowance given by tx ${allowanceTxResponse.hash}`);
    Logger.info(
      `Time so far ${(new Date().getTime() - start.getTime()) / 1000} seconds`
    );
  }
  
  // const gasLimit = erc20Addrs[erc20Asset].gasLimit;
  const response = await l1StandardBridge.depositERC20(
    erc20Addrs[erc20Asset].l1,
    erc20Addrs[erc20Asset].l2,
    FixedPointNumber.fromDecimal(amount.string(), tokenDecimals).toRawString(),
    L2_GAS_LIMIT,
    '0x',
  );
  Logger.info(`Deposit transaction hash (on L1): ${response.hash}`);

  notifications.info(
    'Processing transaction...',
    <VStack alignItems="flex-start">
      <Text>Please be patient as your transaction</Text>
      <Text marginTop="-0.25rem !important">may take some time to process.</Text>
    </VStack>
  );

  await response.wait(1);
  Logger.info('Waiting for status to change to RELAYED');
  Logger.info(
    `Time so far ${(new Date().getTime() - start.getTime()) / 1000} seconds`
  );
  await crossChainMessenger.waitForMessageStatus(
    response.hash,
    MessageStatus.RELAYED
  );

  Logger.info(
    `depositERC20 took ${(new Date().getTime() - start.getTime()) / 1000}
    seconds`
  );
  Logger.info(`Bridged erc20 tx hash: ${response.hash}`);
  const txHashLink = makeTxExplorerLink(mainnet, response.hash); // pass in chain dynamically later on
  notifications.success(
    `Successfully bridged ${erc20Asset}!`,
    txHashLink
      .map((l) => (
        <TxExplorerLink
          explorerName={l.name}
          explorerUrl={l.url}
        />
      ))
      .getOrElse(() => <></>)
  );
}

async function getTokenDetails(
  l1Signer: ethers.providers.JsonRpcSigner,
  amount: Maybe<string>,
  erc20Asset: string,
) {
  const l1TokenContract = new ethers.Contract(erc20Addrs[erc20Asset].l1, erc20ABI, l1Signer);
  const tokenDecimals = await l1TokenContract.decimals();
  const currAllowance = await l1TokenContract.allowance(l1Signer._address, l1Contracts.L1StandardBridge);
  const amountRaw = FixedPointNumber.fromDecimal(amount.string(), tokenDecimals).toRawString();

  return { currAllowance, amountRaw, tokenDecimals };
}
