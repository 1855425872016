import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';

const PageSelector = styled.div`
  color: #303030;
  font-weight: 600;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const PageMover = styled.span<{ disabled: boolean }>`
  padding: 0.625rem;
  color: ${(props) =>
    props.disabled ? `#30303030 !important` : `none !important`};
  &:hover {
    cursor: pointer;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type Props<T extends { items: unknown[] }> = {
  name?: string;
} & T;

const PaginationActions = <T extends { items: unknown[] }>(
  { items }: Props<T>,
  ChildComponent: React.FC<Props<T>>
) => {
  const MAX_ROWS_PER_PAGE = 3;
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = Math.ceil(items.length / MAX_ROWS_PER_PAGE) || 1;

  const data = useMemo(
    () =>
      // eslint-disable-next-line
      items.slice(
        (currentPage - 1) * MAX_ROWS_PER_PAGE,
        Math.min(currentPage * MAX_ROWS_PER_PAGE, items.length) // eslint-disable-line
      ),
    [currentPage, items]
  );

  const setPage = (selectedPage: number) => {
    if (selectedPage < 1 || selectedPage > pageCount) return;
    setCurrentPage(selectedPage);
  };

  return (
    <>
      {/* @ts-ignore */}
      <ChildComponent items={data} />
      <Container>
        <PageSelector>
          <PageMover
            disabled={currentPage === 1}
            onClick={() => setPage(currentPage - 1)}
          >
            <ArrowBackIcon boxSize={5} />
          </PageMover>
          Page&nbsp;
          {currentPage} of&nbsp;{pageCount}
          <PageMover
            disabled={currentPage === pageCount}
            onClick={() => setPage(currentPage + 1)}
          >
            <ArrowForwardIcon boxSize={5} />
          </PageMover>
        </PageSelector>
      </Container>
    </>
  );
};

export const PaginationHOC = <T extends { items: unknown[] }>(
  ChildComponent: React.FC<Props<T>>
): React.FC<Props<T>> => {
  return (props: Props<T>) => PaginationActions(props, ChildComponent);
};
