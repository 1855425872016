import { ethers } from "ethers";
import { Text } from "@chakra-ui/react";
import { getEthersSigner } from "../utils/ethers";
import { Logger } from "../utils/logger";
import { createCrossChainMessenger, erc20Addrs } from "../utils/mainnetCrossChainMessenger";
import { FixedPointNumber } from "../utils/fixedPoint";
import { Maybe } from "../utils/maybe";
import { MessageStatus, TokenBridgeMessage } from "@eth-optimism/sdk";
import { SwitchNetworkAsync } from "./shared";
import { Address } from "viem";
import { Notifications } from "../utils/notifications";
import { makeTxExplorerLink } from "../utils/txNotifications";
import { mainnet } from "viem/chains";
import { ToastTitles } from "../constants/constants";
import { TxExplorerLink } from "../components/TxExplorer/TxExplorerLink";
import { MAINNET_KARAK_RPC, MAINNET_RPC, Karak } from "../contexts/WagmiConfig";

export type WithdrawStatus = 'PENDING_SR_NOT_PUBLISHED' | 'PENDING_IN_CHALLENGE_PERIOD' | 'READY_TO_PROVE' | 'READY_TO_FINALIZE';
export type WithdrawBridgeMessage = TokenBridgeMessage & {
  status: WithdrawStatus;
}

export async function startWithdrawEthFromL2(
  amount: Maybe<string>,
  switchNetworkAsync: SwitchNetworkAsync,
  currentChainId: number | undefined,
  notifications: Notifications,
) {
  if (currentChainId !== 2410) { // if current connected network is not karak, trigger switch to karak
    await switchNetworkAsync?.(2410);
  }

  const l1Signer = new ethers.providers.JsonRpcProvider(MAINNET_RPC);
  const l2Signer = await getEthersSigner({ chainId: 2410 });

  if (!l1Signer || !l2Signer) {
    throw new Error('Data still loading - l1 or l2 signer still missing');
  }

  try {
    Logger.info('Begin withdraw eth from l2');
    const start = new Date();
    const crossChainMessenger = await createCrossChainMessenger(
      l1Signer,
      l2Signer
    );
    const response = await crossChainMessenger.withdrawETH(
      FixedPointNumber.fromDecimal(amount.string(), 18).toRawString()
    );
    Logger.info(`Transaction hash (on L2): ${response.hash}`);

    await response.wait(1);

    Logger.info(
      `startWithdrawETHFromL2 took ${(new Date().getTime() - start.getTime()) / 1000}
      seconds`
    );
    Logger.info(`Start withdraw eth tx hash: ${response.hash}`);
    const txHashLink = makeTxExplorerLink(Karak, response.hash);
    notifications.success(
      ToastTitles.BridgeStartWithdrawEth.successMsg,
      txHashLink
        .map((l) => (
          <TxExplorerLink
            explorerName={l.name}
            explorerUrl={l.url}
          />
        ))
        .getOrElse(() => <></>)
    );
  } catch (e) {
    Logger.error('Error beginning withdraw ETH', e);
    notifications.error(
      ToastTitles.BridgeStartWithdrawEth.errorMsg,
      <Text>Check console for error</Text>
    );
  }
}

export async function startWithdrawERC20FromL2(
  amount: Maybe<string>,
  erc20Asset: string,
  switchNetworkAsync: SwitchNetworkAsync,
  currentChainId: number | undefined,
  notifications: Notifications,
) {
  if (currentChainId !== 2410) { // if current connected network is not karak, trigger switch to karak
    await switchNetworkAsync?.(2410);
  }
  
  const l1Signer = new ethers.providers.JsonRpcProvider(MAINNET_RPC);
  const l2Signer = await getEthersSigner({ chainId: 2410 });
  
  if (!l1Signer || !l2Signer) {
    throw new Error('Data still loading - l1 or l2 signer still missing');
  }
  
  try {
    Logger.info('Begin withdraw erc20 from l2');
    const start = new Date();
    const crossChainMessenger = await createCrossChainMessenger(
      l1Signer,
      l2Signer
    );
    const response = await crossChainMessenger.withdrawERC20(
      erc20Addrs[erc20Asset].l1,
      erc20Addrs[erc20Asset].l2,
      FixedPointNumber.fromDecimal(amount.string(), erc20Addrs[erc20Asset].decimals).toRawString()
    );
    Logger.info(`Transaction hash (on L2): ${response.hash}`);

    await response.wait(1);
  
    Logger.info(
      `startWithdrawERC20FromL2 took ${(new Date().getTime() - start.getTime()) / 1000}
      seconds`
    );
    Logger.info(`Start bridging withdraw ${erc20Asset} tx hash: ${response.hash}`);
    const txHashLink = makeTxExplorerLink(Karak, response.hash);
    notifications.success(
      `Successfully started ${erc20Asset} withdraw! Make sure to check the Outgoing tab to complete your withdrawals.`,
      txHashLink
        .map((l) => (
          <TxExplorerLink
            explorerName={l.name}
            explorerUrl={l.url}
          />
        ))
        .getOrElse(() => <></>)
    );
  } catch (e) {
    Logger.error(`Error starting bridge withdraw ${erc20Asset}`, e);
    notifications.error(
      `Error starting ${erc20Asset} withdraw`,
      <Text>Check console for error</Text>
    );
  }
}

export async function getPendingWithdrawals(address: Address) {
  const l1Signer = new ethers.providers.JsonRpcProvider(MAINNET_RPC);
  const l2Signer = new ethers.providers.JsonRpcProvider(MAINNET_KARAK_RPC);
  const pendingWithdrawals: WithdrawBridgeMessage[] = [];

  const crossChainMessenger = await createCrossChainMessenger(
    l1Signer,
    l2Signer
  );

  const allWithdrawals = await crossChainMessenger.getWithdrawalsByAddress(address);

  for (const withdrawal of allWithdrawals) {
    const msgStatus = await crossChainMessenger.getMessageStatus(withdrawal.transactionHash);
    if (msgStatus === MessageStatus.STATE_ROOT_NOT_PUBLISHED) { // started withdraw but not proved yet
      pendingWithdrawals.push({
        ...withdrawal,
        status: 'PENDING_SR_NOT_PUBLISHED',
      })
    }
    if (msgStatus === MessageStatus.READY_TO_PROVE) {
      pendingWithdrawals.push({
        ...withdrawal,
        status: 'READY_TO_PROVE'
      })
    }
    if (msgStatus === MessageStatus.IN_CHALLENGE_PERIOD) { // proved withdraw but not ready to finalize yet
      pendingWithdrawals.push({
        ...withdrawal,
        status: 'PENDING_IN_CHALLENGE_PERIOD'
      })
    }
    if (msgStatus === MessageStatus.READY_FOR_RELAY) {
      pendingWithdrawals.push({
        ...withdrawal,
        status: 'READY_TO_FINALIZE'
      })
    }
  }

  return pendingWithdrawals;
}

export async function proveUserWithdrawal(
  txHash: string,
  switchNetworkAsync: SwitchNetworkAsync,
  currentChainId: number | undefined,
  notifications: Notifications,
) {
  if (currentChainId !== 1) { // if current connected network is not eth mainnet, trigger switch to eth mainnet (in this case eth mainnet is L1)
    await switchNetworkAsync?.(1);
  }
    
  const l1Signer = await getEthersSigner({ chainId: 1 });
  const l2Signer = new ethers.providers.JsonRpcProvider(MAINNET_KARAK_RPC);
    
  if (!l1Signer || !l2Signer) {
    throw new Error('Data still loading - l1 or l2 signer still missing');
  }

  try {
    Logger.info('Proving withdrawal');
    const crossChainMessenger = await createCrossChainMessenger(
      l1Signer,
      l2Signer
    );

    Logger.info('Prove message');
    await crossChainMessenger.proveMessage(txHash);
    Logger.info('Confirm message is in challenge period');
    await crossChainMessenger.waitForMessageStatus(txHash, MessageStatus.IN_CHALLENGE_PERIOD);

    const txHashLink = makeTxExplorerLink(mainnet, txHash);
    notifications.success(
      ToastTitles.BridgeProveWithdraw.successMsg,
      txHashLink
        .map((l) => (
          <TxExplorerLink
            explorerName={l.name}
            explorerUrl={l.url}
          />
        ))
        .getOrElse(() => <></>)
    );
  } catch (e) {
    Logger.error('Error proving user withdrawal', e);
    notifications.error(
      ToastTitles.BridgeProveWithdraw.errorMsg,
      <Text>Check console for error</Text>
    );
  }
}

export async function finishUserWithdrawal(
  txHash: string,
  switchNetworkAsync: SwitchNetworkAsync,
  currentChainId: number | undefined,
  notifications: Notifications,
) {
  if (currentChainId !== 1) { // if current connected network is not eth mainnet, trigger switch to eth mainnet (in this case eth mainnet is L1)
    await switchNetworkAsync?.(1);
  }
    
  const l1Signer = await getEthersSigner({ chainId: 1 });
  const l2Signer = new ethers.providers.JsonRpcProvider(MAINNET_KARAK_RPC);
    
  if (!l1Signer || !l2Signer) {
    throw new Error('Data still loading - l1 or l2 signer still missing');
  }

  try {
    Logger.info('Completing withdrawal');

    const crossChainMessenger = await createCrossChainMessenger(
      l1Signer,
      l2Signer
    );

    Logger.info('Confirm message is ready for relay');
    await crossChainMessenger.waitForMessageStatus(txHash, MessageStatus.READY_FOR_RELAY);

    Logger.info('Ready for relay, finalizing message now');
    await crossChainMessenger.finalizeMessage(txHash);

    Logger.info('Waiting for status to change to relayed');
    await crossChainMessenger.waitForMessageStatus(txHash, MessageStatus.RELAYED);

    Logger.info(`Finish bridging withdraw tx hash: ${txHash}`);
    const txHashLink = makeTxExplorerLink(mainnet, txHash);
    notifications.success(
      ToastTitles.BridgeFinishWithdraw.successMsg,
      txHashLink
        .map((l) => (
          <TxExplorerLink
            explorerName={l.name}
            explorerUrl={l.url}
          />
        ))
        .getOrElse(() => <></>)
    );
  } catch (e) {
    Logger.error('Error finishing user withdrawal', e);
    notifications.error(
      ToastTitles.BridgeFinishWithdraw.errorMsg,
      <Text>Check console for error</Text>
    );
  }
}

