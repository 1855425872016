
export enum NetworkDetails {
  WB = 'The current token balance in wallet on selected chain',
  AB = 'The current token balance in wallet on Karak',
  GAB = 'The current token balances in wallet on Karak',
  EDT = 'The estimated time to process deposit transaction on bridge',
  EWT = 'The estimated time to process withdraw transaction on bridge',
}

export const ToastTitles: {
  [action: string]: { successMsg: string; errorMsg: string };
} = {
  BridgeDepositEth: {
    successMsg: 'Successfully bridged ETH!',
    errorMsg: 'Error bridging ETH'
  },
  BridgeDepositUSDC: {
    successMsg: 'Successfully bridged USDC!',
    errorMsg: 'Error bridging USDC'
  },
  BridgeStartWithdrawEth: {
    successMsg: 'Successfully started ETH withdraw! Make sure to check the Outgoing tab to complete your withdrawals.',
    errorMsg: 'Error starting ETH withdraw',
  },
  BridgeStartWithdrawUSDC: {
    successMsg: 'Successfully started USDC withdraw!',
    errorMsg: 'Error starting USDC withdraw',
  },
  BridgeProveWithdraw: {
    successMsg: 'Successfully proved withdraw!',
    errorMsg: 'Error proving withdraw',
  },
  BridgeFinishWithdraw: {
    successMsg: 'Successfully finished withdraw!',
    errorMsg: 'Error finishing withdraw',
  },
};

export const L2_GAS_LIMIT = 200_000;
