import React, { useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import {
  Button,
  Spinner,
  Text,
} from '@chakra-ui/react';
import ethLogo from '../../../assets/network-logos/eth.svg';
import optLogo from '../../../assets/network-logos/optimism.svg';
import arbLogo from '../../../assets/network-logos/arbitrum.svg';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';
import { MultiTokenInput } from './MultiTokenInput';
import { Maybe } from '../../../utils/maybe';
import { Logger } from '../../../utils/logger';
import { performDepositERC20, performDepositEth, performHyperlaneTransfer } from '../../../actions/onboarding-deposit';
import { startWithdrawERC20FromL2, startWithdrawEthFromL2 } from '../../../actions/offboarding-withdraw';
import { checkInsufficientBalance, decimalFormat, mapRawAmountToUi } from '../../../utils/helpers';
import { L2UserBalances, UserBalances } from '../../../hooks/userBalances';
import { useKarakBalances } from '../../../contexts/KarakBalances';
import { useNotifications } from '../../../utils/notifications';
import { FixedPointNumber } from '../../../utils/fixedPoint';
import { ToolTip } from '../../ToolTip/ToolTip';
import { NetworkDetails } from '../../../constants/constants';
import { RoutesMap } from '../../../hyperlane/types';
import { KarakBridgeCollateral } from '../../../utils/collateralBalances';
import { arbitrum, mainnet, optimism } from 'viem/chains';
import { ethMainnet, Karak } from '../../../contexts/WagmiConfig';
import { getAddress } from 'viem';

const ButtonFlexbox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const SubHeading = styled.p`
  color: #303030;
  font-family: Inter;
  font-size: 0.85rem;
  margin-right: auto;
  margin-bottom: 0.75rem;
`;

const Flexbox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const NetworkBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid transparent;
  width: 30%;
`;

const NetworkImg = styled.img`
  width: 2.5rem;
  margin-bottom: 0.5rem;
`;

const NetworkName = styled.p`
  color: #303030;
  font-size: 0.75rem;
  font-weight: 700;
`;

const InfoFlex = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  margin-top: 1.5rem;
`;

const InfoBox = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: #FFF4ED;
  padding: 0.75rem 1rem 0.75rem 1rem;
`;

const InfoText = styled.p`
  color: var(--components-primary-orange);
  font-family: Nunito Sans;
  font-weight: 700;
  font-size: 0.8rem;
`;

const DarkInfoText = styled.p`
  color: #303030;
  font-family: Inter;
  font-weight: 700;
  font-size: 0.85rem;
  margin-left: auto;
`;

interface Props {
  action: string;
  tokenRoutes: RoutesMap | undefined;
  l1Balances: UserBalances;
  l2Balances: L2UserBalances;
  bridgeCollateral: KarakBridgeCollateral;
}

const SUPPORTED_BRIDGE_CHAINS = [
  {
    name: 'Ethereum',
    src: ethLogo,
    alt: 'eth',
    chainInfo: ethMainnet,
  },
  {
    name: 'Optimism',
    src: optLogo,
    alt: 'optimism',
    chainInfo: optimism,
  },
  {
    name: 'Arbitrum',
    src: arbLogo,
    alt: 'arbitrum',
    chainInfo: arbitrum,
  },
];

type AssetInfo = {
  [key in 'ETH' | 'USDC' | 'rswETH']: {
    karakBalance: string | null;
    l1Balance: string;
    opBalance: string;
    arbBalance: string;
    decimals: number;
  };
};

export const DepositWithdraw: React.FC<Props> = ({ action, tokenRoutes, l1Balances, l2Balances, bridgeCollateral }) => {
  const [amount, setAmount] = useState<Maybe<string>>(Maybe.none());
  const [asset, setAsset] = useState<string>('ETH');
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedChain, setSelectedChain] = useState<any>(SUPPORTED_BRIDGE_CHAINS[0].chainInfo); // eslint-disable-line
  const [insufficientCollateral, setInsufficientCollateral] = useState<boolean>(false);
  const { chain } = useNetwork();
  const { address } = useAccount();
  const { switchNetworkAsync } = useSwitchNetwork();
  const notifications = useNotifications();
  const actionHeading = action.charAt(0).toUpperCase() + action.slice(1);

  const { karakBalances, refreshKarakBalances } = useKarakBalances();

  const assetInfo: AssetInfo = useMemo(() => {
    return {
      'ETH': { 
        karakBalance: karakBalances.ethBalance,
        l1Balance: l1Balances.ethBalance,
        opBalance: l2Balances.optimism.ethBalance,
        arbBalance: l2Balances.arbitrum.ethBalance,
        decimals: 18,
      },
      'USDC': { 
        karakBalance: karakBalances.usdcBalance,
        l1Balance: l1Balances.usdcBalance,
        opBalance: l2Balances.optimism.usdcBalance,
        arbBalance: l2Balances.arbitrum.usdcBalance,
        decimals: 6,
      },
      'rswETH': { 
        karakBalance: karakBalances.rswEthBalance,
        l1Balance: l1Balances.rswEthBalance,
        opBalance: l2Balances.optimism.rswEthBalance,
        arbBalance: l2Balances.arbitrum.rswEthBalance,
        decimals: 18,
      },
    };
  }, [karakBalances, l1Balances, l2Balances]);

  const handleInputChange = useCallback((v: string) => {
    setAmount(v.trim() ? Maybe.from(v) : Maybe.none());
    if (selectedChain?.id === optimism.id || selectedChain?.id === arbitrum.id) {
      const enteredAmount = v.trim();
      const collateralBalances = action === 'deposit' 
        ?  bridgeCollateral['karak'] : selectedChain?.id === optimism.id
        ? bridgeCollateral['optimism'] : bridgeCollateral['arbitrum'];
      const isInsufficient  = // TODO here for rswETH once hyperlane supported for it
        (asset === 'ETH' && checkInsufficientBalance(enteredAmount, 18, collateralBalances.ethBalance)) ||
        (asset === 'USDC' && checkInsufficientBalance(enteredAmount, 6,  collateralBalances.usdcBalance));
      setInsufficientCollateral(isInsufficient);
    }
  }, [action, asset, bridgeCollateral, selectedChain?.id]);

  const isInSufficientCollateral = useMemo(() =>
    (selectedChain?.id === optimism.id || selectedChain?.id === arbitrum.id) &&
    insufficientCollateral,
  [selectedChain?.id, insufficientCollateral]);

  const maxAmount = useMemo(() => {
    if (action === 'withdraw') {
      return FixedPointNumber.fromRaw(BigInt(
        assetInfo[asset as keyof AssetInfo].karakBalance ?? '0'),
        assetInfo[asset as keyof AssetInfo].decimals
      ).toString();
    }
    switch (selectedChain?.id) {
      case mainnet.id:
        return FixedPointNumber.fromRaw(BigInt(
          assetInfo[asset as keyof AssetInfo].l1Balance ?? '0'),
          assetInfo[asset as keyof AssetInfo].decimals
        ).toString();
      case optimism.id:
        return FixedPointNumber.fromRaw(BigInt(
          assetInfo[asset as keyof AssetInfo].opBalance ?? '0'),
          assetInfo[asset as keyof AssetInfo].decimals
        ).toString();
      case arbitrum.id:
        return FixedPointNumber.fromRaw(BigInt(
          assetInfo[asset as keyof AssetInfo].arbBalance ?? '0'),
          assetInfo[asset as keyof AssetInfo].decimals
        ).toString();
      default:
        return '0.00'; // no network selected
    }
  }, [action, asset, assetInfo, selectedChain]);

  const insufficientBalance = useMemo(() => 
    FixedPointNumber.fromDecimal(
      amount.string(),
      assetInfo[asset as keyof AssetInfo].decimals
    ).toBigInt() > FixedPointNumber.fromDecimal(
      maxAmount,
      assetInfo[asset as keyof AssetInfo].decimals
    ).toBigInt()
  , [amount, asset, assetInfo, maxAmount]);

  const isZeroAmount = useMemo(() => 
    FixedPointNumber.fromDecimal(
      amount.string(),
      assetInfo[asset as keyof AssetInfo].decimals
    ).toBigInt() === BigInt(0)
  , [amount, asset, assetInfo]);

  // move this to util to make component less bloated
  const getWalletBalance = () => {
    switch (selectedChain?.id) {
      case mainnet.id:
        return decimalFormat(mapRawAmountToUi(
          assetInfo[asset as keyof AssetInfo].l1Balance,
          assetInfo[asset as keyof AssetInfo].decimals
        ));
      case optimism.id:
        return decimalFormat(mapRawAmountToUi(
          assetInfo[asset as keyof AssetInfo].opBalance,
          assetInfo[asset as keyof AssetInfo].decimals
        ));
      case arbitrum.id:
        return decimalFormat(mapRawAmountToUi(
          assetInfo[asset as keyof AssetInfo].arbBalance, assetInfo[asset as keyof AssetInfo].decimals
        ));
      default:
        return '0.00'; // no network selected
    }
  }

  const depositAsset = useCallback(async (): Promise<void> => {
    if (!address || !chain || !selectedChain.id) return; // TODO add back in tokenRoutes once op/arb enabled again
    try {
      setLoading(true);
      if (selectedChain.id === mainnet.id) {
        if (asset === 'ETH') {
          await performDepositEth(amount, switchNetworkAsync, chain.id, notifications);
        } else {
          await performDepositERC20(amount, asset, switchNetworkAsync, chain.id, notifications);
        }
      } else { // use hyperlane for optimism and arbitrum
        await performHyperlaneTransfer(
          address,
          selectedChain.id,
          Karak.id, // destination for deposit always karak
          chain.id,
          tokenRoutes!,
          amount,
          switchNetworkAsync,
          notifications,
          asset
        );
      }
      setLoading(false);
      setAmount(Maybe.none());
      refreshKarakBalances();
    } catch (e) {
      setLoading(false);
      Logger.error(`Error depositing ${asset} onto Karak network:`, e);
      notifications.error(
        `Error bridging ${asset}`,
        <Text>Check console for error</Text>
      );
    }
  }, [address, asset, chain, tokenRoutes, selectedChain, amount, notifications, switchNetworkAsync, refreshKarakBalances]);

  const withdrawAsset = useCallback(async (): Promise<void> => {
    if (!address || !chain || !selectedChain.id) return; // TODO add back in tokenRoutes once op/arb enabled again
    try {
      setLoading(true);
      if (selectedChain.id === mainnet.id) {
        if (asset === 'ETH') {
          await startWithdrawEthFromL2(amount, switchNetworkAsync, chain.id, notifications);
        } else {
          await startWithdrawERC20FromL2(amount, asset, switchNetworkAsync, chain.id, notifications);
        }
      } else { // use hyperlane for optimism and arbitrum
        await performHyperlaneTransfer(
          address,
          Karak.id, // origin for withdraw always karak
          selectedChain.id,
          chain.id,
          tokenRoutes!,
          amount,
          switchNetworkAsync,
          notifications,
          asset
        );
      }
      setLoading(false);
      setAmount(Maybe.none());
      refreshKarakBalances();
    } catch (e) {
      setLoading(false);
      Logger.error(`Error beginning withdraw ${asset}:`, e);
      notifications.error(
        `Error starting ${asset} withdraw`,
        <Text>Check console for error</Text>
      );
    }
  }, [address, asset, chain, tokenRoutes, selectedChain, amount, notifications, switchNetworkAsync, refreshKarakBalances]);

    return (
    <>
      <div>
        {action === 'withdraw' && (
          <p style={{ color: 'var(--components-primary-orange)', fontSize: '0.65rem', marginBottom: '1rem' }}>
            Please note that K2 Bridge withdraw is a 3-step process. First, start your withdraw on this tab, and then wait ~4 hrs to prove your withdraw in the Outgoing tab. Once you prove your withdraw, you must wait 3 days to finish your withdraw.
            After starting a withdrawal, you can track its status in the Outgoing tab.
          </p>
        )}
        <SubHeading>
          Choose a network to {action} assets {action === 'deposit' ? 'from' : 'to'}.
        </SubHeading>
        {action === 'withdraw' && selectedChain && selectedChain.id !== mainnet.id && (
          <p style={{ color: 'orange', fontSize: '0.75rem', marginBottom: '1rem' }}>
            Please note that transfers from Karak to {selectedChain.name} may take ~1-2 hrs to complete.
          </p>
        )}
        <Flexbox>
          {SUPPORTED_BRIDGE_CHAINS.map((c, index) => (
            <NetworkBox
              className={
                c.chainInfo.id === arbitrum.id || c.chainInfo.id === optimism.id ? 'network-div-disabled' :
                selectedChain?.id === c.chainInfo.id
                ? 'network-div-focus'
                : 'network-div'
              }
              tabIndex={0}
              key={index}
              onClick={() => {
                setSelectedChain(c.chainInfo);
              }}
            >
              <NetworkImg src={c.src} alt={c.alt} />
              <NetworkName>{c.name}</NetworkName>
            </NetworkBox>
          ))}
        </Flexbox>
        <MultiTokenInput
          actionType={action}
          asset={asset}
          tokens={['ETH', 'USDC', 'rswETH']}
          amount={amount.string()}
          maxAmount={maxAmount}
          onChange={handleInputChange}
          setAsset={setAsset}
          disabled={false}
        />
        {isInSufficientCollateral && (
          <p style={{ color: 'red', fontSize: '0.85rem' }}>
            Insufficient liquidity on this route
          </p>
        )}
        <InfoFlex>
          <InfoBox>
            <InfoText>
              WALLET BALANCE
              <ToolTip tooltipText={NetworkDetails.WB} />
            </InfoText>
            <DarkInfoText>
              {getWalletBalance()}{' '}{asset}
            </DarkInfoText>
          </InfoBox>
          <InfoBox>
            <InfoText>
              ACCOUNT BALANCE
              <ToolTip tooltipText={NetworkDetails.AB} />
            </InfoText>
            <DarkInfoText>
              {decimalFormat(mapRawAmountToUi(
                assetInfo[asset as keyof AssetInfo].karakBalance ?? '0',
                assetInfo[asset as keyof AssetInfo].decimals
              ))}
                {' '}{asset}
            </DarkInfoText>
          </InfoBox>
          <InfoBox>
            <InfoText>
              ESTIMATED {actionHeading.toUpperCase()} TIME
              <ToolTip tooltipText={action === 'deposit' ? NetworkDetails.EDT : NetworkDetails.EWT} />
            </InfoText>
            <DarkInfoText>{action === 'deposit' ? '1 min(s)' : '3 days'}</DarkInfoText>
          </InfoBox>
        </InfoFlex>
        <ButtonFlexbox>
          {/* <Button
            _hover={{
              textDecoration: 'none',
              filter: 'brightness(85%)',
            }}
            _active={{ color: 'transparent' }}
            _focus={{ outline: 'none', boxShadow: 'none' }}
            px="var(--button-px)"
            py="var(--button-py)"
            textColor="white"
            background="transparent"
            border="0.5px solid #DF5E00"
            borderRadius="5px"
            fontSize="0.9rem"
            onClick={() => {
              bridgeOrigin === 'no-funds-page'
              ? setPage([false, true, false, false])
              : setPage([false, false, false, true])
            }}
            width="48%"
            marginTop="2.5rem"
          >
            Back
          </Button> */}
          <Button
            isDisabled={
              amount.string() === '' ||
              isZeroAmount ||
              !selectedChain ||
              isInSufficientCollateral ||
              selectedChain?.id === arbitrum.id ||
              selectedChain?.id === optimism.id ||
              insufficientBalance ||
              address === getAddress('0x6e5A4296722608Ba6d52f94e21f66310c36668df')
            }
            _hover={{
              textDecoration: 'none',
              filter: 'brightness(85%)',
            }}
            _active={{ color: 'transparent' }}
            _focus={{ outline: 'none', boxShadow: 'none' }}
            px="var(--button-px)"
            py="var(--button-py)"
            textColor="white"
            background="linear-gradient(90deg, #DF5E00 0%, #FF9C54 100%)"
            borderRadius="5px"
            fontFamily="Nunito Sans"
            fontWeight="700"
            fontSize="1rem"
            onClick={
              action === 'withdraw'
              ? withdrawAsset
              : depositAsset
            }
            width="100%"
            marginTop="2.5rem"
            height="3rem"
          >
            {loading ? (
              <Spinner /> 
            ) : (
              insufficientBalance ? (
                "Insufficient Balance"
              ) : (
                `${actionHeading} ${asset}`
              )
            )}
          </Button>
        </ButtonFlexbox>
      </div>
    </>
  );
};
