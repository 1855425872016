import { ProtocolType } from '@hyperlane-xyz/utils';

import { Logger } from '../../utils/logger';
import { getProtocolType } from '../caip/chains';
import { getTokenAddress, isNativeToken } from '../caip/tokens';
import { getEvmProvider } from '../wallet';

import { getErc20Contract } from '../contracts/evmContracts';
import { Route } from '../types';
import { isRouteFromCollateral, isWarpRoute } from '../routes/utils';

export async function isApproveRequired(
  route: Route,
  tokenCaip19Id: TokenCaip19Id,
  amount: string,
  owner: Address,
) {
  if (
    isNativeToken(tokenCaip19Id) ||
    !isRouteFromCollateral(route) ||
    !isWarpRoute(route) ||
    getProtocolType(route.originCaip2Id) !== ProtocolType.Ethereum
  ) {
    return false;
  }
  const spender = route.baseRouterAddress;
  const provider = getEvmProvider(route.originCaip2Id);
  const tokenAddress = getTokenAddress(tokenCaip19Id);
  const contract = getErc20Contract(tokenAddress, provider);
  const allowance = await contract.allowance(owner, spender);
  const isRequired = allowance.lt(amount);
  Logger.debug(`Approval is${isRequired ? '' : ' not'} required for transfer of ${tokenCaip19Id}`);
  return isRequired;
}
