import React from 'react';
import { Link } from '@chakra-ui/react';

interface Props {
  explorerUrl: string;
  explorerName: string;
}

export const TxExplorerLink: React.FC<Props> = ({
  explorerName,
  explorerUrl,
}) => {
  return (
    <Link isExternal href={explorerUrl}>
      View TX on {explorerName}
    </Link>
  );
};
