import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Button } from '@chakra-ui/react';
import { useAccount } from 'wagmi';

interface Props {
  location?: string;
  disabled?: boolean;
}

export const ButtonConnect: React.FC<Props> = ({ location, disabled }) => {
  const { address } = useAccount();
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus ||
            authenticationStatus === 'authenticated');

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              'style': {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <Button
                    isDisabled={disabled}
                    _hover={{textDecoration: 'none', filter: 'brightness(85%)'}}
                    _active={{color: 'transparent'}}
                    _focus={{outline: 'none', boxShadow: 'none'}}
                    textColor="white"
                    background="var(--components-primary-orange)"
                    fontSize="0.85rem"
                    borderRadius="5px"
                    width="9rem"
                    height="2.25rem"
                    fontFamily="Nunito Sans"
                    fontWeight="700"
                    onClick={openConnectModal}
                  >
                    Connect wallet
                  </Button>
                );
              }

              if (location === 'no funds') {
                return (
                  <Button
                    _hover={{textDecoration: 'none', filter: 'brightness(85%)'}}
                    _active={{color: 'transparent'}}
                    _focus={{outline: 'none', boxShadow: 'none'}}
                    px="var(--button-px)"
                    py="var(--button-py)"
                    textColor="white"
                    background="var(--components-primary-orange)"
                    fontSize="0.9rem"
                    borderRadius="5px"
                    width="11rem"
                    fontFamily="Nunito Sans"
                    fontWeight="700"
                    onClick={openAccountModal}
                  >
                    Try another wallet
                  </Button>
                )
              }

              return (
                <Button
                  _hover={{textDecoration: 'none', filter: 'brightness(85%)'}}
                  _active={{color: 'transparent'}}
                  _focus={{outline: 'none', boxShadow: 'none'}}
                  px="var(--button-px)"
                  py="var(--button-py)"
                  textColor="#303030"
                  background="#fff"
                  boxShadow="4px 4px 12px 4px rgba(0, 0, 0, 0.10) !important"
                  fontSize="0.9rem"
                  borderRadius="5px"
                  width="11rem"
                  fontFamily="Nunito Sans"
                  fontWeight="700"
                  onClick={openAccountModal}
                >
                  {address?.substring(0, 6)}...{address?.substring(36, 42)}
                </Button>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};