import React from "react";
import { Box } from "@chakra-ui/react";
import styled from 'styled-components';

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 5rem;
  max-height: 5rem;
`;

const Logo = styled.img`
  width: 85%;
  margin: auto;
`;

const SLogo = styled.img`
  width: 100%;
  margin: auto;
`;

const Firm = ({ logo, altText }) => {
  return (
    <Container>
      {altText === 'Mubadala Capital' || altText === 'Proof Group' ? (
        <SLogo src={logo} alt={altText} />
      ) : (
        <Logo src={logo} alt={altText} />
      )}
    </Container>
  );
}

export default Firm;
