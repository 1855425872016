import React from "react";
import styled from "styled-components";
import { Link as RouteLink } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import { ArrowForwardIcon } from "@chakra-ui/icons";
import kuda from '../../assets/ecosystem-logos/kuda.svg';
import wormhole from '../../assets/ecosystem-logos/wormhole.svg';
import spacetime from '../../assets/ecosystem-logos/spacetime.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6rem;
`;

const HeadingTitle = styled.h1`
  text-align: center;
  color: #303030;
  font-size: 2.5rem;
  font-family: Gilroy;
  font-weight: 800;
  letter-spacing: -0.96px;
  z-index: 999;
  margin-bottom: 1.5rem;
`;

const Text = styled.p`
  text-align: center;
  color: #303030;
  font-family: Nunito Sans;
  font-size: var(--p-font-size);
  z-index: 999;
  margin-bottom: 2.5rem;
  width: 50rem;

  @media only screen and (max-width: 867px) {
    width: 100%;
  }
`;

const EcosystemContainer = styled.div`
  display: grid;
  grid-template-columns: 23% 23% 23% 23%;
  justify-content: center;
  column-gap: 1rem;

  @media only screen and (max-width: 1375px) {
    row-gap: 1rem;
    grid-template-columns: 35% 35%;
  }

  @media only screen and (max-width: 697px) {
    grid-template-columns: 16rem;
  }
`;

const AppDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
`;

const AppImg = styled.img`
  width: 2rem;
  margin-bottom: 1rem;
`;

const AppName = styled.p`
  color: #303030;
  font-family: Nunito Sans;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
`;

const AppDesc = styled.p`
  color: #303030;
  font-family: Nunito Sans;
  font-size: 0.85rem;
`;

const IntegrateDiv = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: linear-gradient(90deg, #DF5E00 0%, #FF9C54 100%);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
  padding: 2rem 1.5rem;
`;

const IntegrateText = styled.p`
  color: #FFF;
  font-family: Inter;
  font-weight: 600;
  font-size: 1rem;
  line-height: 150%;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  column-gap: 0.5rem;
`;

const ExploreEcosystem = () => {
  return (
    <Container>
      <HeadingTitle>Explore the ecosystem</HeadingTitle>
      <Text>
        Zero to One systems are being developed on Karak every day. Discover and explore cutting-edge
        web services, networks, protocols, and applications being built within the Karak ecosystem.
      </Text>
      <EcosystemContainer>
        <AppDiv>
          <AppImg src={kuda} alt="kuda" />
          <AppName>KUDA</AppName>
          <AppDesc>Karak Universal Data Availability, KUDA, is the first DA marketplace designed to seamlessly connect rollups with any DA layer.</AppDesc>
        </AppDiv>
        <AppDiv>
          <AppImg src={wormhole} alt="wormhole" />
          <AppName>Wormhole</AppName>
          <AppDesc>Wormhole is the leading interoperability platform that facilitates the secure transfer of arbitrary messages across chains.</AppDesc>
        </AppDiv>
        <AppDiv>
          <AppImg src={spacetime} alt="space-and-time" />
          <AppName>Space and Time</AppName>
          <AppDesc>Space and Time is the first decentralized data warehouse that delivers sub-second ZK proofs against onchain and offchain data.</AppDesc>
        </AppDiv>
        <IntegrateDiv>
          <IntegrateText>Explore one of the fastest growing ecosystems in the industry. Build the future of web services.</IntegrateText>
          <ButtonContainer>
            <Button
              as={RouteLink}
              to="/ecosystem"
              _hover={{textDecoration: 'none', filter: 'brightness(95%)'}}
              _active={{color: 'transparent'}}
              _focus={{outline: 'none', boxShadow: 'none'}}
              px="var(--button-px)"
              py="var(--button-py)"
              textColor="white"
              border="0.2px solid #FFF"
              background="rgba(255, 255, 255, 0.28)"
              fontSize="0.85rem"
              borderRadius="5px"
              fontFamily="Inter"
              fontWeight="700"
              width="100%"
            >
              Explore
              <ArrowForwardIcon boxSize={3.5} ml={"0.25rem"} />
            </Button>
          </ButtonContainer>
        </IntegrateDiv>
      </EcosystemContainer>
    </Container>
  );
}

export default ExploreEcosystem;
