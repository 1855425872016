import { AdapterFactory } from "../tokens/AdapterFactory";
import { IHypTokenAdapter } from '@hyperlane-xyz/sdk';
import { toWei } from '@hyperlane-xyz/utils';
import { Route, RoutesMap, TransferFormValues } from "../types";
import { getTokenRoute, isRouteFromNative, isWarpRoute } from "../routes/utils";
import { Logger } from "../../utils/logger";
import { BigNumber } from "ethers";
import { isApproveRequired } from "../tokens/approval";
import { ensureSufficientCollateral } from "./utils";
import { getEthereumChainId, parseCaip2Id } from "../caip/chains";
import { getMultiProvider } from "../wallet";
import { Notifications } from "../../utils/notifications";
import { SwitchNetworkAsync } from "../../actions/shared";
import { PrepareSendTransactionArgs, prepareSendTransaction, sendTransaction, waitForTransaction } from "@wagmi/core";
import { TxExplorerLink } from "../../components/TxExplorer/TxExplorerLink";

interface ExecuteTransferParams {
  weiAmountOrId: string;
  destinationDomainId: DomainId;
  recipientAddress: Address;
  tokenRoute: Route;
  userAddress: Address;
  activeChainCaip2Id: ChainCaip2Id;
  notifications: Notifications;
  switchNetworkAsync: SwitchNetworkAsync,
}

interface ExecuteHypTransferParams extends ExecuteTransferParams {
  hypTokenAdapter: IHypTokenAdapter;
}

export async function executeTransfer({
  values,
  tokenRoutes,
  userAddress,
  activeChainCaip2Id,
  notifications,
  switchNetworkAsync,
}: {
  values: TransferFormValues;
  tokenRoutes: RoutesMap;
  userAddress: Address;
  activeChainCaip2Id: ChainCaip2Id;
  notifications: Notifications;
  switchNetworkAsync: SwitchNetworkAsync;
}) {
  Logger.debug('Preparing transfer transaction');

  try {
    const { originCaip2Id, destinationCaip2Id, tokenCaip19Id, amount, recipientAddress } = values;
    const { reference: destReference } = parseCaip2Id(destinationCaip2Id);
    const destinationDomainId = getMultiProvider().getDomainId(destReference);

    const tokenRoute = getTokenRoute(originCaip2Id, destinationCaip2Id, tokenCaip19Id, tokenRoutes);
    if (!tokenRoute) throw new Error('No token route found between chains');

    const weiAmountOrId = toWei(amount, tokenRoute.originDecimals);
    if (!userAddress) throw new Error('No active account found for origin chain');
  
  
    const executeParams: ExecuteTransferParams = {
      weiAmountOrId,
      destinationDomainId,
      recipientAddress,
      tokenRoute,
      userAddress,
      activeChainCaip2Id,
      notifications,
      switchNetworkAsync,
    };

    let transferTxHash: string;
    if (isWarpRoute(tokenRoute)) {
      transferTxHash = await executeHypTransfer(executeParams);
    } else {
      throw new Error('Unsupported route type');
    }
  
    Logger.info('Transfer transaction confirmed, hash:', transferTxHash);
    Logger.info('Remote transfer started!', `${transferTxHash} ${originCaip2Id}`);

    notifications.success(
      'Transfer successful!',
      <TxExplorerLink
        explorerName={"Hyperlane"}
        explorerUrl={`https://hyperlane-explorer.karak.network/?search=${transferTxHash}`}
      />
    );
  } catch (error) {
    if (JSON.stringify(error).includes('ChainMismatchError')) {
    // Wagmi switchNetwork call helps prevent this but isn't foolproof
      Logger.error('Wallet must be connected to origin chain');
    } else {
      Logger.error('Unable to transfer tokens');
      notifications.error(
        'Error starting transfer',
        'Check console for error',
      );
    }
  }
}

export async function executeHypTransfer(params: ExecuteTransferParams) {
  const { tokenRoute, weiAmountOrId } = params;
  const hypTokenAdapter = AdapterFactory.HypTokenAdapterFromRouteOrigin(tokenRoute);
  const paramsWithAdapter = { ...params, hypTokenAdapter };
  
  await ensureSufficientCollateral(tokenRoute, weiAmountOrId);
  const result = await executeEvmTransfer(paramsWithAdapter);

  return result;
}

export async function executeEvmTransfer({
  weiAmountOrId,
  destinationDomainId,
  recipientAddress,
  tokenRoute,
  hypTokenAdapter,
  userAddress,
  activeChainCaip2Id,
  notifications,
  switchNetworkAsync,
}: ExecuteHypTransferParams) {

  if (!isWarpRoute(tokenRoute)) throw new Error('Unsupported route type');

  const { baseRouterAddress, originCaip2Id, baseTokenCaip19Id } = tokenRoute;
  const chainId = getEthereumChainId(originCaip2Id);

  if (activeChainCaip2Id !== originCaip2Id) {
    await switchNetworkAsync?.(chainId);
  }

  const isApproveTxRequired = await isApproveRequired(tokenRoute, baseTokenCaip19Id, weiAmountOrId, userAddress);

  if (isApproveTxRequired) {
    const tokenAdapter = AdapterFactory.TokenAdapterFromAddress(baseTokenCaip19Id);
    const approveTxRequest = await tokenAdapter.populateApproveTx({
      weiAmountOrId,
      recipient: baseRouterAddress,
    });
    const prepareApproveTx = await prepareSendTransaction(approveTxRequest as PrepareSendTransactionArgs);
    const approveTx = await sendTransaction(prepareApproveTx);
    const approveTxReceipt = await waitForTransaction({ confirmations: 1, hash: approveTx.hash });

    Logger.info('Approve transaction confirmed, hash:', approveTxReceipt.transactionHash);

    notifications.success(
      'ERC20 Approval Successful!',
      <TxExplorerLink
        explorerName={"Hyperlane"}
        explorerUrl={`https://hyperlane-explorer.karak.network/?search=${approveTxReceipt.transactionHash}`}
      />
    );
  }
  

  const gasPayment = await hypTokenAdapter.quoteGasPayment(destinationDomainId);  // The added quoteDispatch function is just a breakdown of this one.
  Logger.debug('Quoted gas payment', gasPayment);
  // If sending native tokens (e.g. Eth), the gasPayment must be added to the tx value and sent together
  const txValue = isRouteFromNative(tokenRoute)
    ? BigNumber.from(gasPayment).add(weiAmountOrId.toString())
    : gasPayment;

  const transferTxRequest = await hypTokenAdapter.populateTransferRemoteTx({
    weiAmountOrId: weiAmountOrId.toString(),
    recipient: recipientAddress,
    destination: destinationDomainId,
    txValue: txValue.toString(),
  });
  const prepareTransferTx = await prepareSendTransaction(transferTxRequest as PrepareSendTransactionArgs);
  const transferTx = await sendTransaction(prepareTransferTx);
  const transferTxReceipt = await waitForTransaction({ confirmations: 1, hash: transferTx.hash });
  
  return transferTxReceipt.transactionHash;
}
