import React from 'react';
import styled from 'styled-components';
import GlobeAnimation from './GlobeAnimation';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  column-gap: 2rem;
  margin: auto auto 5rem auto;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    column-gap: 0rem;
  }
`;

const ColoredContainer = styled.div`
  position: absolute;
  margin-left: calc(50% - 50vw);
  left: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  background: linear-gradient(90deg, #DF5E00 0%, #FF9C54 100%);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
  width: 30rem;
  z-index: 999 !important;

  @media only screen and (max-width: 767px) {
    align-items: center;
    margin: auto;
    padding-top: 4rem;
  }

  @media only screen and (max-width: 500px) {
    width: 100%;
    padding-top: 5rem;
  }
`;

const Heading = styled.h2`
  color: white;
  opacity: 90%;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 3rem;
  line-height: 106%;

  @media only screen and (max-width: 1211px) {
    font-size: 3rem;
  }

  @media only screen and (max-width: 500px) {
    font-size: 2.5rem;
  }
`;

const SubHeading = styled.p`
  color: #FFFFFF;
  text-shadow: 0px 4px 11.7px rgba(0, 0, 0, 0.25);
  font-family: Inter;
  line-height: 111.5%;
  font-size: 1rem;
`;

const GlobalTrust = () => {
  return (
    <Container>
      <ColoredContainer />
      <TextBox>
        <Heading>
          Secured by a global decentralized network of trust
        </Heading>
        <SubHeading>
          Karak leverages a global network of decentralized trust, introducing a unique,
          universal marketplace where stakers and developers converge to spark innovation
          and secure the next wave of novel applications together.
        </SubHeading>
      </TextBox>
      <GlobeAnimation />
    </Container>
  );
}

export default GlobalTrust;