import { useState, useEffect } from 'react';
import { Button, Link } from '@chakra-ui/react';
import styled from 'styled-components';
import kuda from '../../assets/ecosystem-logos/kuda-float.svg';
import spacetime from '../../assets/ecosystem-logos/spacetime-float.svg';
import wormhole from '../../assets/ecosystem-logos/wormhole-float.svg';
import arbitrum from '../../assets/ecosystem-logos/arb-float.svg';
import { ITEMS } from './AppsAndIntegrations';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;
`;

const Blur = styled.div`
  position: absolute;
  top: 5%;
  width: 100vw;
  height: 30vh;
  opacity: 0.5;
  background: var(--components-primary-orange);
  filter: blur(205px);
  z-index: 0 !important;

  @media only screen and (max-width: 849px) {
    top: 3%;
  }

  @media only screen and (max-width: 598px) {
    top: 1%;
  }
`;

const Heading = styled.h1`
  color: #303030;
  font-family: Gilroy;
  font-weight: 700;
  font-size: 3rem;
  line-height: var(--header-line-height);
  filter: brightness(100%);
`;

const SubHeading = styled.p`
  font-size: 1rem;
  font-family: Nunito Sans;
  font-weight: 500;
  color: #303030;
  overflow-wrap: break-word;
  filter: brightness(100%);

  @media only screen and (max-width: 525px) {
    width: 100%;
  }
`;

const HeroDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5rem;
  width: 100%;
  margin-bottom: 5rem;
  z-index: 1 !important;

  @media only screen and (max-width: 768px) {
    column-gap: 1rem;
    justify-content: start;
    align-items: start;
  }

  @media only screen and (max-width: 598px) {
    column-gap: 0rem;
  }
`;

const HeroTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: start;
  width: 45%;

  @media only screen and (max-width: 598px) {
    width: 100%;
  }
`;

const FloatingImg = styled.img`
  width: 7rem;
  height: 7rem;
`;

const VPDiv = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  @media only screen and (max-width: 598px) {
    display: none;
  }
`;

const HPDiv = styled.div`
  display: flex;
  column-gap: 2rem;
`;

const TabsDiv = styled.div`
  display: flex;
  column-gap: 1rem;
  justfiy-content: start;
  margin-right: auto;
  margin-bottom: 2rem;
  margin-top: 5rem;

  @media only screen and (max-width: 496px) {
    row-gap: 1rem;
    flex-wrap: wrap;
  }
`;

const EcosytemGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1.5rem;
  row-gap: 2rem;

  @media only screen and (max-width: 1355px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (max-width: 786px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 531px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const AppDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  height: 17rem;
  z-index: 1 !important;

  @media only screen and (max-width: 531px) {
    width: 20rem;
  }

  @media only screen and (max-width: 400px) {
    width: 100%;
  }

  &:hover {
    cursor: pointer;
    filter: brightness(98%);
    text-decoration: none !important;
  }

  &:focus {
    box-shadow: none !important;
  }
`;

const AppHeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AppImg = styled.img`
  width: 2rem;
  margin-bottom: 1rem;
`;

const AppImgDiv = styled.div`
  width: 2rem;
  height: 3rem;
`;

const AppName = styled.p`
  color: #303030;
  font-family: Nunito Sans;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
`;

const AppDesc = styled.p`
  color: #303030;
  font-family: Nunito Sans;
  font-size: 0.85rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  line-clamp: 6;
`;

const TypePill = styled.div`
  display: flex;
  height: fit-content;
  padding: 0rem 0.75rem;
  border-radius: 150px;
  border: 0.2px solid rgba(223, 94, 0, 0.50);
  background: #FFF4ED;
  font-weight: 700;
  color: var(--components-primary-orange);
  font-size: 0.75rem;
  margin-top: 0.4rem;
`;


const Ecosystem = () => {
  const [selectedTab, setSelectedTab] = useState("All");
  const tabTypes = ["All", "Project", "Operator"];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page>
      <Blur />
      <HeroDiv>
        <HeroTextDiv>
          <Heading>Karak Ecosystem</Heading>
          <SubHeading>An overview of the world-class applications, protocols, and networks in the Karak ecosystem.</SubHeading>
          <Button
            as={Link}
            _hover={{textDecoration: 'none', filter: 'brightness(85%)'}}
            _active={{color: 'transparent'}}
            _focus={{outline: 'none', boxShadow: 'none'}}
            px="var(--button-px)"
            py="var(--button-py)"
            textColor="white"
            background="var(--components-primary-orange)"
            fontSize="1rem"
            borderRadius="5px"
            width="12rem"
            height="3rem"
            fontFamily="Nunito Sans"
            fontWeight="700"
            href="https://airtable.com/appomORhKwfeQrCIk/shrla3jWOjs7fId8j"
            isExternal
          >
            Get in touch
          </Button>
        </HeroTextDiv>
        <VPDiv>
          <HPDiv>
            <FloatingImg src={kuda} alt="kuda" />
            <FloatingImg src={spacetime} alt="space-and-time" />
          </HPDiv>
          <HPDiv style={{ marginLeft: "3rem" }}>
            <FloatingImg src={wormhole} alt="wormhole" />
            <FloatingImg src={arbitrum} alt="arbitrum" />
          </HPDiv>
        </VPDiv>
      </HeroDiv>
      <TabsDiv>
        {tabTypes.map((type, index) => (
          <Button
            key={index}
            _hover={{textDecoration: 'none', filter: 'brightness(95%)'}}
            _active={{color: 'transparent'}}
            _focus={{outline: 'none', boxShadow: 'none'}}
            px="1.25rem"
            py="0.5rem"
            textColor={selectedTab === type ? "white" : "#303030"}
            background={selectedTab === type ? "var(--components-primary-orange)" : "transparent"}
            border={selectedTab === type ? "0.2px solid transparent" : "0.2px solid #B7B7B7"}
            fontSize="0.85rem"
            borderRadius="100px"
            height="2rem"
            fontWeight="600"
            onClick={() => setSelectedTab(type)}
          >
            {type}
          </Button>
        ))}
      </TabsDiv>
      <EcosytemGrid>
        {ITEMS.filter((item) => (selectedTab === "All" || item.type === selectedTab)).map((item, index) =>
          <AppDiv key={index} onClick={() => { window.open(item.link) }}>
            <AppHeaderDiv>
              <AppImgDiv>
                <AppImg src={item.src} alt={item.alt} />
              </AppImgDiv>
              <TypePill>{item.type}</TypePill>
            </AppHeaderDiv>
            <AppName>{item.name}</AppName>
            <AppDesc>{item.desc}</AppDesc>
          </AppDiv>
        )}
      </EcosytemGrid>
    </Page>
  );
}

export default Ecosystem;
