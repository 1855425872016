import { useState, useEffect } from "react";
import { useAccount } from "wagmi";
import {
  // getUserKarakTestnetBalances,
  // getUserL1GoerliBalances,
  getUserL2Balances,
  // getUserKarakMainnetBalances,
  getUserL1EthBalances,
} from "../utils/userBalances";
import { Logger } from "../utils/logger";
import { findCollateralTokenAddressByChainId } from "../hyperlane/tokens/metadata";
import { arbitrum, optimism } from "viem/chains";
import { fetchTokenRoutes } from "../hyperlane/routes/token-routes";
import { Karak } from "../contexts/WagmiConfig";

export interface UserBalances {
  ethBalance: string;
  usdcBalance: string;
  rswEthBalance: string;
}

export interface L2UserBalances {
  optimism: UserBalances;
  arbitrum: UserBalances;
}

export function useL1Balances() {
  const [l1Balances, setl1Balances] = useState({
    ethBalance: '0',
    usdcBalance: '0',
    rswEthBalance: '0',
  });
  const { address } = useAccount();

  useEffect(() => {
    const fetchL1Balances = async () => {
      if (!address) return;
      try {
        const { ethBalance, usdcBalance, rswEthBalance } = await getUserL1EthBalances(address);
        setl1Balances({ ethBalance, usdcBalance, rswEthBalance });
      } catch (error) {
        Logger.error('Error fetching Eth L1 balances:', error);
      }
    }

    fetchL1Balances();
  }, [address]);

  return l1Balances;
}

const L2_BRIDGING_DISABLED_FOR_NOW = true;

export function useL2Balances() {
  const [l2Balances, setl2Balances] = useState({
    optimism: { usdcBalance: '0', ethBalance: '0', rswEthBalance: '0' },
    arbitrum: { usdcBalance: '0', ethBalance: '0', rswEthBalance: '0' },
  });
  const { address } = useAccount();
  const optimismTokenAddress = findCollateralTokenAddressByChainId(optimism.id);
  const arbitrumTokenAddress = findCollateralTokenAddressByChainId(arbitrum.id);

  useEffect(() => {
    const fetchData = async () => {
      if (!address || L2_BRIDGING_DISABLED_FOR_NOW) return;
      try {
        // Fetch token routes
        const tokenRoutes = await fetchTokenRoutes();

        // Fetch L2 balances for Optimism
        const optimismBalances = await getUserL2Balances(
          address,
          tokenRoutes,
          optimism.id,
          Karak.id, // Destination chain ID (constant which is karak)
          optimismTokenAddress
        );

        // Fetch L2 balances for Arbitrum
        const arbitrumBalances = await getUserL2Balances(
          address,
          tokenRoutes,
          arbitrum.id,
          Karak.id, // Destination chain ID (constant which is karak)
          arbitrumTokenAddress
        );

        setl2Balances({
          optimism: {
            ...optimismBalances,
            rswEthBalance: '0',
          },
          arbitrum: {
            ...arbitrumBalances,
            rswEthBalance: '0',
          },
        });
      } catch (error) {
        Logger.error('Error fetching optimism/arbitrum balances:', error);
      }
    };

    fetchData();
  }, [address, arbitrumTokenAddress, optimismTokenAddress]);

  return l2Balances;
}
