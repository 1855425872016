import { Maybe } from './maybe';
import { Chain } from 'wagmi';

export function makeTxExplorerLink(
  chain: Chain,
  txHash: string
): Maybe<{ url: string; name: string }> {
  const explorer = Maybe.from(chain.blockExplorers?.default);
  const txHashLink = explorer.map((e) => `${e.url}/tx/${txHash}`);

  return txHashLink.zip(explorer).map(([t, e]) => ({
    name: e.name,
    url: t,
  }));
}
