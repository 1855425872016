import { ethers } from "ethers";
import { Address } from "wagmi";
import { MAINNET_RPC, MAINNET_KARAK_RPC, GOERLI_RPC, GOERLI_KARAK_RPC } from "../contexts/WagmiConfig";
import { erc20ABI } from "../constants/abis/erc20";
import { erc20Addrs as mainnetERC20Addrs } from "./mainnetCrossChainMessenger";
import { erc20Addrs as testnetERC20Addrs } from "./testnetCrossChainMessenger";
import { RoutesMap } from "../hyperlane/types";
import { getCaip2Id } from "../hyperlane/caip/chains";
import { AssetNamespace, getCaip19Id } from "../hyperlane/caip/tokens";
import { ProtocolType } from "@hyperlane-xyz/utils";
import { getUserOriginBalance } from "../hyperlane/tokens/balances";

export async function getUserKarakMainnetBalances(address: Address) {
  const l2Signer = new ethers.providers.JsonRpcProvider(MAINNET_KARAK_RPC)
    .getSigner(address);
  const usdcERC20 = new ethers.Contract(mainnetERC20Addrs.USDC.l2, erc20ABI, l2Signer);
  const rswEthERC20 = new ethers.Contract(mainnetERC20Addrs.rswETH.l2, erc20ABI, l2Signer);
  
  const ethBalance = (await l2Signer.getBalance()).toString();
  const usdcBalance = (await usdcERC20.balanceOf(address)).toString() as string;
  const rswEthBalance = (await rswEthERC20.balanceOf(address)).toString() as string;

  return { ethBalance, usdcBalance, rswEthBalance };
}

export async function getUserL1EthBalances(address: Address) {
  const l1Signer = new ethers.providers.JsonRpcProvider(MAINNET_RPC)
    .getSigner(address);
  const usdcERC20 = new ethers.Contract(mainnetERC20Addrs.USDC.l1, erc20ABI, l1Signer);
  const rswEthERC20 = new ethers.Contract(mainnetERC20Addrs.rswETH.l1, erc20ABI, l1Signer);
    
  const ethBalance = (await l1Signer.getBalance()).toString();
  const usdcBalance = (await usdcERC20.balanceOf(address)).toString() as string;
  const rswEthBalance = (await rswEthERC20.balanceOf(address)).toString() as string;
  
  return { ethBalance, usdcBalance, rswEthBalance };
}

export async function getUserL2Balances(
  address: Address,
  tokenRoutes: RoutesMap,
  originChainId: number,
  destinationChaindId: number,
  tokenAddress: string, // in our case we'll be passing in the origin chain's USDC address
) {
  const originCaip2Id = getCaip2Id(ProtocolType.Ethereum, originChainId);
  const destinationCaip2Id = getCaip2Id(ProtocolType.Ethereum, destinationChaindId);
  const tokenCaip19Id = getCaip19Id(originCaip2Id, AssetNamespace.erc20, tokenAddress);
  // getUserOriginBalance method returns the balance + decimals of the specified token which in this case is the chain's usdc address
  // and returns the balance of the native token which is the eth balance
  const balance = await getUserOriginBalance(originCaip2Id, destinationCaip2Id, tokenCaip19Id, tokenRoutes, address);
  return {
    usdcBalance: balance?.tokenBalance ?? '0',
    ethBalance: balance?.nativeBalance ?? '0',
  };
}

export async function getUserKarakTestnetBalances(address: Address) {
  const l2Signer = new ethers.providers.JsonRpcProvider(GOERLI_KARAK_RPC)
    .getSigner(address);
  const l2ERC20 = new ethers.Contract(testnetERC20Addrs.l2, erc20ABI, l2Signer);
  
  const ethBalance = (await l2Signer.getBalance()).toString();
  const usdcBalance = (await l2ERC20.balanceOf(address)).toString() as string;

  return { ethBalance, usdcBalance };
}

export async function getUserL1GoerliBalances(address: Address) {
  const l1Signer = new ethers.providers.JsonRpcProvider(GOERLI_RPC)
    .getSigner(address);
  const l1ERC20 = new ethers.Contract(testnetERC20Addrs.l1, erc20ABI, l1Signer);
    
  const ethBalance = (await l1Signer.getBalance()).toString();
  const usdcBalance = (await l1ERC20.balanceOf(address)).toString() as string;
  
  return { ethBalance, usdcBalance };
}
