import React from 'react';
import { Box } from "@chakra-ui/react";
import styled from 'styled-components';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 5rem;
  max-height: 5rem;
`;

const Name = styled.h2`
  font-size: var(--h2-font-size);
  font-weight: 600;
  color: #303030;
  font-family: Nunito Sans;
`;

const Occupation = styled.p`
  font-size: var(--p2-font-size);
  font-weight: 600;
  color: #303030;
  font-family: Nunito Sans;
`;

const Angel = ({ name, occupation }) => {
  return (
    <Container>
      <Name>{name}</Name>
      <Occupation>{occupation}</Occupation>
    </Container>
  );
}

export default Angel;
