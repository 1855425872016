import { WagmiConfig, createConfig, configureChains } from 'wagmi';
import { Chain, arbitrumGoerli, goerli, optimismGoerli, arbitrum, optimism, mainnet } from 'viem/chains';
import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc';
import { publicProvider } from '@wagmi/core/providers/public';
import { Buffer } from 'buffer';
import '@rainbow-me/rainbowkit/styles.css';
import {
  RainbowKitProvider,
  connectorsForWallets,
  DisclaimerComponent,
} from '@rainbow-me/rainbowkit';
import {
  rainbowWallet,
  walletConnectWallet,
  metaMaskWallet,
  coinbaseWallet,
  trustWallet,
  rabbyWallet,
  phantomWallet,
  injectedWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { useDisclosure } from '@chakra-ui/react';
import styled from 'styled-components';
import { TOSModal } from '../components/Karak-XP/TOS/TOSModal';

if (!window.Buffer) {
  window.Buffer = Buffer;
}

const TosText = styled.p`
  color: #0e76fd;
  font-size: 12px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const TOSFlexBox = styled.div`
  display: flex;
  column-gap: 5px;

  @media only screen and (max-width: 796px) {
    flex-direction: column;
    column-gap: 0px;
  }
`;

export const GOERLI_RPC = 'https://eth-goerli.g.alchemy.com/v2/gx1ymsOTeeKSbUnZfcYVKHJ689vlvDiY';
export const GOERLI_KARAK_RPC = 'https://goerli.node1.karak.network';

export const KarakTestnet: Chain = {
  id: 2511,
  name: 'KarakTestnet',
  network: 'karak-testnet',
  nativeCurrency: {
    name: 'Karak Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://goerli.node1.karak.network'],
    },
    public: {
      http: [],
      webSocket: undefined
    }
  },
  blockExplorers: {
    default: {
      name: 'Blockscout',
      url: 'https://goerli.scan.karak.network',
    }
  }
};

export const MAINNET_KARAK_RPC = 'https://rpc.karak.network';
export const Karak: Chain = {
  id: 2410,
  name: 'Karak',
  network: 'karak',
  nativeCurrency: {
    name: 'Karak Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: [MAINNET_KARAK_RPC],
    },
    public: {
      http: [MAINNET_KARAK_RPC],
      webSocket: ['wss://wss.rpc.karak.network'],
    }
  },
  blockExplorers: {
    default: {
      name: 'Blockscout',
      url: 'https://explorer.karak.network',
    }
  }
};

export const MAINNET_RPC = 'https://wiser-magical-owl.quiknode.pro/cf5c701f186e3e508e9ad5fa79a63e9095812fc0'; // mainnet eth rpc
const MAINNET_PUBLIC_NODE_RPC = 'https://ethereum-rpc.publicnode.com'
export const ethMainnet = {
  ...mainnet,
  rpcUrls: {
    ...mainnet.rpcUrls,
    default: {
      http: [MAINNET_RPC, MAINNET_PUBLIC_NODE_RPC],
    },
    public: {
      http: [MAINNET_RPC, MAINNET_PUBLIC_NODE_RPC],
    },
  },
};

const OPTIMISM_RPC = 'https://wiser-magical-owl.optimism.quiknode.pro/cf5c701f186e3e508e9ad5fa79a63e9095812fc0';
const OPTIMISM_PUBLIC_NODE_RPC = 'https://optimism.publicnode.com';
export const optimismMainnet = {
  ...optimism,
  rpcUrls: {
    ...optimism.rpcUrls,
    default: {
      http: [OPTIMISM_RPC, OPTIMISM_PUBLIC_NODE_RPC],
    },
    public: {
      http: [OPTIMISM_RPC, OPTIMISM_PUBLIC_NODE_RPC],
    },
  },
};

const ARBITRUM_RPC = 'https://wiser-magical-owl.arbitrum-mainnet.quiknode.pro/cf5c701f186e3e508e9ad5fa79a63e9095812fc0';
const ARBITRUM_PUBLIC_NODE_RPC = 'https://arbitrum-one.publicnode.com';
export const arbitrumMainnet = {
  ...arbitrum,
  rpcUrls: {
    ...arbitrum.rpcUrls,
    default: {
      http: [ARBITRUM_RPC, ARBITRUM_PUBLIC_NODE_RPC],
    },
    public: {
      http: [ARBITRUM_RPC, ARBITRUM_PUBLIC_NODE_RPC],
    },
  },
};

export const { chains, publicClient, webSocketPublicClient } = configureChains(
  [Karak, ethMainnet, optimismMainnet, arbitrumMainnet, KarakTestnet, arbitrumGoerli, optimismGoerli, goerli],
  [
    jsonRpcProvider({
      rpc: (c) => {
        return { http: c.rpcUrls.default.http[0] };
      },
    }),
    publicProvider(),
    jsonRpcProvider({
      rpc: () => {
        return {
          http: 'https://mainnet.gateway.tenderly.co/2Vb3fLTZYhtEIYGYYhykVf',
        };
      },
    })
  ],
);

const appName = 'Karak Network';
const projectId = 'b54116432eba7ee0029f73ae6423cd0b';
const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [
        metaMaskWallet({ projectId, chains }),
        rainbowWallet({ projectId, chains }),
        walletConnectWallet({ projectId, chains }),
        injectedWallet({ chains }),
        coinbaseWallet({ appName, chains }),
        rabbyWallet({ chains }),
        trustWallet({ projectId, chains }),
        phantomWallet({ chains }),
      ],
    },
  ],
);
  
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

interface Props {
  children: React.ReactNode;
}

const Disclaimer: DisclaimerComponent = ({ Text }) => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  return (
    <TOSFlexBox>
      <Text>By connecting, I agree to Karak XP's </Text>
      <Text>
        <TosText onClick={onOpen}>Terms of Service</TosText>
      </Text>
      <TOSModal isOpen={isOpen} onClose={onClose} />
    </TOSFlexBox>
  );
};

export function WagmiProvider({ children }: Props) {
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains} appInfo={{ disclaimer: Disclaimer }}>
        {children}
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

